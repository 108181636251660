import {
    EmployeeActionItemState,
    EmployeeActionItemStateInfo,
    EvaluationActionItemStateAsEnum,
    getActionItemStates
} from "../../../models/dtos/EmployeeActionItemState";
import { useCallback, useMemo } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { EmployeeActionItemCreateDTO } from "../../../models/dtos/EmployeeActionItemCreateDTO";
import { EmployeeActionItemsService } from "../../../services/EmployeeActionItemsService";
import { EmployeeFollowUpItemStatusInfo } from "../../../models/dtos/EmployeeFollowUpItemStatus";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddEmployeeActionItemPopupProps {
    employeeId: string;
    locationId: string;
    onCompletedOperations: () => void;
}


const labelItemStatusSelector = (item: EmployeeActionItemStateInfo) => item.description;
const idItemStatusSelector = (item: EmployeeActionItemStateInfo) => item.id;


var svc = new EmployeeActionItemsService();

export function AddEmployeeActionItemPopup(props: IAddEmployeeActionItemPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const createActionItemCall = useServiceCallPro(svc.createEmployeeActionItem);


    const actionItemStates = useMemo(() => {
        return getActionItemStates();
    }, []);

    const itemNameForm = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const itemDescriptionForm = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const itemStartDateForm = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const itemEndDateForm = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const itemStateForm = useFormControl<EmployeeFollowUpItemStatusInfo>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });


    /****************************
    * DATA REQUESTS
    *****************************/


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/


    /****************************
    * USER ACTIONS
    *****************************/



    const handleSelectClicked = useCallback(() => {

        if (!AllValid(itemNameForm.validate(), itemDescriptionForm.validate(), itemStartDateForm.validate(), itemStateForm.validate())) return;

        const requestDTO: EmployeeActionItemCreateDTO = {
            itemName: "" + itemNameForm.value,
            itemDescription: "" + itemDescriptionForm.value,
            startDate: "" + itemStartDateForm.value?.toISO(),
            endDate: itemEndDateForm.value?.toISO() || null,
            state: itemStateForm.value?.id ? EvaluationActionItemStateAsEnum[parseInt(itemStateForm?.value?.id)] : EmployeeActionItemState.REGISTERED
        };

        createActionItemCall.invoke(props.locationId, props.employeeId, requestDTO)
            .then(() => {
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("EMPLOYEES.ITEMS.POPUPS.AddFollowUpItemSuccess")}
                />);
                props.onCompletedOperations();
                closePopup();

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId,
        props.employeeId,
    itemNameForm.value,
    itemDescriptionForm.value,
    itemStartDateForm.value,
    itemStateForm.value,
    itemEndDateForm.value,
    itemNameForm.validate,
    itemDescriptionForm.validate,
    itemStartDateForm.validate,
    itemStateForm.validate,
    itemEndDateForm.validate,
    createActionItemCall.invoke,
        createNotification,
        closePopup,
        openPopup,
    props.onCompletedOperations]);





    return <PopupContainer className="popup-add-employee-item">
        {createActionItemCall.isLoading ? <FullScreenLoader /> : null}
        <PopupHeader title={translate("EMPLOYEES.ITEMS.POPUPS.AddFollowUpItem")} />
        <PopupContent>
            <FormSection isInputGap childrenFlexDirColumn>
                <FormFieldTextInput
                    formControl={itemNameForm}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Name")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.NamePlaceholder")}
                />
                <FormFieldTextArea
                    formControl={itemDescriptionForm}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Description")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.DescriptionPlaceholder")}
                />
                <FormSection isInputGap>
                    <FormFieldDatePicker
                        formControl={itemStartDateForm}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDate")}
                        placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDatePlaceholder")}
                        maxDate={itemEndDateForm.value}
                    />
                    <FormFieldDatePicker
                        formControl={itemEndDateForm}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDate")}
                        placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDatePlaceholder")}
                        minDate={itemStartDateForm.value}
                    />
                </FormSection>
                <FormFieldSelectSingle
                    formControl={itemStateForm}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Status")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.StatusPlaceholder")}
                    options={actionItemStates}
                    labelSelector={labelItemStatusSelector}
                    idSelector={idItemStatusSelector}
                    maxHeightOptions={"16rem"}
                />
            </FormSection>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Add"),
                        type: "primary",
                        onClick: handleSelectClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}