import "./EmployeeCertificationsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AddEmployeeCertificationPopup } from "./popups/AddEmployeeCertificationPopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { ReactComponent as AttachmentIcon } from "../../../../lib/assets/icons/anexo.svg";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { EditEmployeeCertificationPopup } from "./popups/EditEmployeeCertificationPopup";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EmployeeCertificationStateTranslationTerms } from '../../../../models/enums/EmployeeCertificationState';
import { EmployeeCertificationsService } from "../../services/EmployeeCertificationsService";
import { EmployeeCertificationsSummaryDTO } from "../../models/dtos/EmployeeCertificationsSummaryDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { ImportPopup } from "../../../../lib/components/popup/ImportPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveButton } from "../../../../lib/components/buttons/ResponsiveButton";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import axios from "axios";
import { getEmployeeCertificationStatusCss } from "../../../../common/helpers/getEmployeeCertificationStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new EmployeeCertificationsService();

const pagePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];

export function EmployeeCertificationsPage() {
    const { isLoadingPermissions, hasPermission } = useHasPermissions(pagePermissions);
    const windowResize = useWindowResize();
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    
    const { employeeId } = useParams();

    const [certifications, setCertifications] = useState<EmployeeCertificationsSummaryDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const getCertificationsCall = useServiceCallPro(svc.getEmployeeCertifications);
    const deleteCertificationCall = useServiceCallPro(svc.deleteEmployeeCertification);
    const uploadCertificationsFileCall = useServiceCallPro(svc.uploadEmployeeCertificationsFile);

    const getUrlDownloadAttachmentCall = useServiceCallPro(svc.getUrlDownloadAttachment);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getCertifications = useCallback((controller: AbortController | undefined) => {
        if (!employeeId) return;

        getCertificationsCall.invoke(locationIDAsString, employeeId, { page: currentpage, pageLength: itemsPerPage }, controller?.signal)
            .then((data) => {
                if (data) {
                    setCertifications(data.employeeCertifications);
                    setTotalItems(data.totalItems)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, getCertificationsCall.invoke, currentpage, itemsPerPage, openPopup]);



    const downloadAttachment = useCallback((certificationId: number, fileId: number) => {
        if (!employeeId) return;

        getUrlDownloadAttachmentCall.invoke(locationIDAsString, employeeId, certificationId, fileId)
            .then((url) => window.open(url, "_blank"))
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationIDAsString, employeeId, getUrlDownloadAttachmentCall, openPopup]);



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        if (!employeeId) return;

        getCertifications(controller);

        return () => { controller.abort() };
    }, [employeeId, currentpage, itemsPerPage]);



    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsClicked = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleAddCertificateButtonClicked = useCallback(() => {
        openPopup(<AddEmployeeCertificationPopup employeeId={employeeId} onCompletedOperations={() => getCertifications(undefined)} />)
    }, [openPopup, employeeId, getCertifications]);



    const handleDownloadAttachmentClicked = useCallback((certificationId: number, fileId: number) => {
        if (!certificationId || !fileId) return;
        downloadAttachment(certificationId, fileId);
    }, [downloadAttachment]);



    const handleEditCertificationClicked = useCallback((id: number) => {
        openPopup(<EditEmployeeCertificationPopup employeeId={employeeId} certificationId={id} onCompletedOperations={() => getCertifications(undefined)} />)
    }, [openPopup, employeeId, getCertifications]);




    const handleDeleteCertification = useCallback((certificationId: number) => {
        if (!employeeId || !certificationId) return;

        deleteCertificationCall
            .invoke(locationIDAsString, "" + employeeId, "" + certificationId)
            .then(() => {
                getCertifications(undefined);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.DeleteCertificationSuccess")}
                    />
                );
            })
            .catch((error) => {
                if (!error) return;
                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            });
    }, [locationIDAsString, openPopup, employeeId, getCertifications, createNotification, deleteCertificationCall.invoke]);




    const handleDeleteCertificationWarningPopup = useCallback((id: number) => {
        if (!id) return;

        openPopup(
            <WarningPopup
                className="delete-certification-popup"
                onDismissClicked={() => closePopup()}
                onConfirmClicked={() => handleDeleteCertification(id)}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Delete")}
            >
                {translate("EMPLOYEES.CERTIFICATIONS.POPUPS.DeleteCertificationConfirmText")}
            </WarningPopup>
        )
    }, [openPopup, closePopup, handleDeleteCertification]);




    const handleFileImport = useCallback((file: File) => {
        if (!employeeId) return;

        uploadCertificationsFileCall.invoke(locationIDAsString, employeeId, file)
            .then((_) => {
                getCertifications(undefined);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.ImportXLSXSuccess")}
                    />
                );
            }).catch(error => {
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, uploadCertificationsFileCall.invoke, getCertifications, createNotification, openPopup]);




    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeCertificationsSummaryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.certificationName,
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Name"),
            isMobilePrimaryCell: true,
            width: "50%",
        },
        {
            cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.startDate),
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.StartDate"),
            width: "10%",
        },
        {
            cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.endDate),
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.EndDate"),
            width: "10%",
        },
        {
            cellRenderProp: (v) => (
                <Tag
                    text={translate(EmployeeCertificationStateTranslationTerms[v.state])}
                    backgroundColor={getEmployeeCertificationStatusCss(v.state)}
                    isTiny={windowResize < 1024}
                />
            ),
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.State"),
            isStatusCell: true,
            width: "10%",
        },
        {
            cellRenderProp: (v) =>
                <div className="employee-info">
                    <div className="small-copy employee-name">
                        {v.createdBy.userDisplayName ?? v.createdBy.userEmail}
                    </div>
                    <div className="caption employee-id">{v.createdBy.userEmployeeID ?? "-"}</div>
                </div>,
            width: "15%",
            headerRender: translate("COMMON.RegisteredBy"),
            isMobilePrimaryCell: true,
        },

        {
            cellRenderProp: (v) => v.attachmentId &&
                <Tooltip text={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.ViewAttachment")}>
                    <ScalableIconButton
                        icon={<AttachmentIcon />}
                        onClick={() => v.attachmentId && handleDownloadAttachmentClicked(v.employeeCertificationId, v.attachmentId)}
                        size={24}
                    />
                </Tooltip>,
            width: "24px",
            isMobileHeaderIcon: true,
        },
        {

            cellRenderProp: (v) => {
                return (
                    <Tooltip text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertification")}>
                        <ScalableIconButton
                            icon={<EditIcon />}
                            onClick={() => v.employeeCertificationId && handleEditCertificationClicked(v.employeeCertificationId)}
                            size={24}
                        />
                    </Tooltip>
                );
            },
            width: "24px",
            isMobileHeaderIcon: true,
        },
        {
            cellRenderProp: (v) => {
                return (
                    <Tooltip text={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DeleteCertification")}>
                        <ScalableIconButton
                            icon={<DeleteIcon />}
                            onClick={() => v.employeeCertificationId && handleDeleteCertificationWarningPopup(v.employeeCertificationId)}
                            size={24}
                        />
                    </Tooltip>
                );
            },
            width: "24px",
            isMobileHeaderIcon: true,
        }
    ], [windowResize, downloadAttachment, handleEditCertificationClicked, handleDeleteCertificationWarningPopup]);




    const renderActionButtons = useMemo(() => {
        if (isLoadingPermissions || !hasPermission) return;

        return <>
            <Button
                text={translate("COMMON.Import")}
                type="secondary"
                isDisabled
                onClick={() => openPopup(<ImportPopup
                    onClickDownloadTemplateBtn={() => window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Skill_Categories_Template.xlsx", "_blank")} //TODO change template link
                    onClickImportBtn={handleFileImport}
                />)}
            />
            <ResponsiveButton
                text={translate("EMPLOYEES.CERTIFICATIONS.AddCertificate")}
                type="secondary"
                icon={<AddIcon />}
                onClick={handleAddCertificateButtonClicked}
            />
        </>;
    }, [isLoadingPermissions, hasPermission, handleAddCertificateButtonClicked, handleFileImport, openPopup]);


    return <PageLayout
        tabTitle={translate("EMPLOYEES.CERTIFICATIONS.Certifications")}
        pageTitle={translate("EMPLOYEES.CERTIFICATIONS.Certifications")}
        className="certifications-page"
        showBreadcrumb
        actions={renderActionButtons}
    >
        {/*         <FormFieldTextInput
            formControl={searchboxController}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
        /> */}

        {deleteCertificationCall.isLoading && <FullScreenLoader />}
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={certifications || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getCertificationsCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsClicked}
        />
    </PageLayout>;
}