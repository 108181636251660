import "./SkillsCatEvaluationsCard.css"

import { useCallback, useEffect, useState } from "react";

import { ReactComponent as AddRoundIcon } from "../../../../lib/assets/icons/add-round-v2.svg";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EvaluationInput } from "../../../matrices/components/EvaluationInputV2";
import { EvaluationMatrixResponseDTO } from "../../models/EvaluationMatrixResponseDTO";
import { NextRoleDetailsDTO } from "../EvaluationDetailsPage";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { SkillSummaryDTO } from "../../../admin/skills/models/dtos/SkillSummaryDTO";
import { SkillsCategoryEvaluationDTO } from "../../models/SkillsCategoryEvaluationDTO";
import { SkillsEvaluationDTO } from "../../models/SkillsEvaluationDTO";
import { SuggestNextRolePopup } from "../popups/SuggestNextRolePopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

export interface IFieldWrapperProps {
    children: React.ReactNode;
    value: string | number | undefined;
    isLocked: boolean;
}

export function FieldWrapper(props: IFieldWrapperProps) {
    if (props.isLocked) {
        return <>{props.value}</>
    }
    return <>{props.children}</>
}



export interface EvaluationCategory {
    name: string;
    skills: EvaluationSkill[];
}

export interface EvaluationSkill {
    name: string;
    targetEvaluationMatrixEntryId: number;
    competenceLevel: number | null;
    value: string | undefined;
    isValid: boolean;
    skillId: number;
}

interface ISkillsCatEvaluationsCardProps{
    businessLineId?: string;
    roleName: string;
    isEditionModeEnabled: boolean;
    values: EvaluationCategory[]
    OnEvaluationValueChanged: (values : EvaluationCategory[] ) => void;
    nextRoleDetails?: NextRoleDetailsDTO
    OnSuggestNextRoleClicked?: ()=>void
}



export function SkillsCatEvaluationsCard(props: ISkillsCatEvaluationsCardProps) {



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    /****************************
     * USER ACTIONS
     *****************************/
    const handleInputChange = useCallback((value: string, isValid: boolean, targetSkill: EvaluationSkill ) => {
        const updatedSkillsEvaluations = props.values.map(category => {

            const updatedSkills = category.skills.map((skill)=> {
                if(skill.targetEvaluationMatrixEntryId === targetSkill.targetEvaluationMatrixEntryId){
                    const newSkillValue : EvaluationSkill ={
                        ...skill,
                        value: value,
                        isValid: isValid
                    };
                    return newSkillValue;
                }
                return skill;
            });

            const updatedCatgory : EvaluationCategory = {
                ...category,
                skills: updatedSkills
            }
            return updatedCatgory;
           
        });

        props.OnEvaluationValueChanged(updatedSkillsEvaluations)
    }, [props.OnEvaluationValueChanged, props.values]);



    /****************************
     * CSS & HTML
     *****************************/
    const getColumns = useCallback((showTextBtn: boolean): ColumnDefinition<EvaluationSkill>[] => [
        {
            cellRenderProp: (skill) => skill.name,
            headerRender: translate('BUSINESSLINES.MATRIX.INFOANDFORM.Skill'),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (skill) => {
                return (
                    <FieldWrapper value={skill.value} isLocked={!props.isEditionModeEnabled} key={skill.targetEvaluationMatrixEntryId}>
                        <EvaluationInput
                            initialValue={skill.value}
                            onChange={(value,isValid) => handleInputChange(value, isValid, skill)}
                        />
                    </FieldWrapper>
                );
            },
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation"),
            width: "100px"
        },
        {
            cellRenderProp: (skill) => props.businessLineId ? skill.competenceLevel : null,
            headerRender: props.businessLineId ? props.roleName : <></>,
            width: props.businessLineId ? "100px" : "0px"
        },
        {
            cellRenderProp: (skill) => props.businessLineId &&
            <div className="next-role-column">{props.nextRoleDetails && props.nextRoleDetails.skills.find(level => level.skillId === skill.skillId)?.competenceLevel}</div> ,
            
            headerRender: props.businessLineId ? (!props.nextRoleDetails && props.isEditionModeEnabled && showTextBtn ? <TextButton
                text={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.SuggestNextRole")}
                type="primary"
                icon={<AddRoundIcon />} iconBefore
                onClick={props.OnSuggestNextRoleClicked}
                isDisabled={false}
            /> : <div className="next-role-column">{props.nextRoleDetails && props.nextRoleDetails.roleDescription}</div>) : <></>,
            width: props.businessLineId ? "12%" : "0%",
        },
    ], [props.businessLineId, handleInputChange, props.isEditionModeEnabled, props.OnSuggestNextRoleClicked, props.nextRoleDetails]);


    return <>
        {props.values.map((category, index) => (
            <CardContainer key={category.name} className="skills-cat-evaluations-card">
                <div className="card-header">
                    <div className="subtitle">{category.name}</div>
                </div>
                <ResponsiveDataTable
                    columnDefinitions={getColumns(index === 0)}
                    items={category.skills}
                    totalitems={category.skills.length}
                />
            </CardContainer>
        ))}
    </>
}