import "./MyActionItemsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { EmployeeActionItemStateTranslationTerms } from "../../../employees/models/dtos/EmployeeActionItemState";
import { EmployeeActionItemSummaryDTO } from "../../../employees/models/dtos/EmployeeActionItemSummaryDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { MyService } from "../../services/MyService";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Tag } from "../../../../lib/components/tag/Tag";
import axios from "axios";
import { getEvaluationFollowUpItemStatusCss } from "../../../evaluations/helpers/getEvaluationFollowUpItemStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new MyService();

export function MyActionItemsPage() {
    const windowResize = useWindowResize();
    const openPopup = usePopup();
    
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const [items, setItems] = useState<EmployeeActionItemSummaryDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const getMyActionItemsCall = useServiceCallPro(svc.getMyActionItems);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getMyActionItems = useCallback((controller: AbortController | undefined) => {
        if (!locationIDAsString) return;

        getMyActionItemsCall.invoke(locationIDAsString, { page: currentpage, pageLength: itemsPerPage }, controller?.signal)
            .then((data) => {
                if (data) {
                    setItems(data.items);
                    setTotalItems(data.total)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, getMyActionItemsCall, currentpage, itemsPerPage, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        if (!locationIDAsString) return;

        const controller = new AbortController();
        getMyActionItems(controller);

        return () => {
            controller.abort()
        };
    }, [locationIDAsString, currentpage, itemsPerPage]);



    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsClicked = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeActionItemSummaryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.itemName,
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.Name"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => v.itemDescription,
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.Description"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => (
                <Tag
                    text={translate(EmployeeActionItemStateTranslationTerms[v.state])}
                    backgroundColor={getEvaluationFollowUpItemStatusCss(v.state)}
                    isTiny={windowResize < 1024}
                />
            ),
            headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Status"),
            isStatusCell: true,
            width: "120px"
        },
        {
            cellRenderProp: (v) => DateTimeHelper.formatDate(v.startDate),
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDate"),
            width: "140px",
        },

        {
            cellRenderProp: (v) => DateTimeHelper.formatDate(v.endDate),
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDate"),
            width: "140px",
        },
        {
            cellRenderProp: (v) =>
                <div className="employee-info">
                    <div className="small-copy employee-name">
                        {v.createdBy.userDisplayName ?? v.createdBy.userEmail}
                    </div>
                    <div className="caption employee-id">{v.createdBy.userEmployeeID ?? "-"}</div>
                </div>,
            width: "15%",
            headerRender: translate("COMMON.RegisteredBy"),
            isMobilePrimaryCell: true,
        }
    ], [windowResize]);

    return <PageLayout
        tabTitle={translate("EMPLOYEES.ITEMS.FollowUpItems")}
        pageTitle={translate("EMPLOYEES.ITEMS.FollowUpItems")}
        className="my-items-page"
        showBreadcrumb
    >
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={items || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getMyActionItemsCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsClicked}
        />
    </PageLayout>;
}