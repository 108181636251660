import "./EmployeeBonusPage.css";

import { ColumnDefinition, PaginationOptions } from "../../../../lib/components/table/TableInterfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { EmployeeEvaluationPeriodSummaryDTO } from "../../models/dtos/EmployeeEvaluationPeriodSummaryDTO";
import { EmployeeEvaluationsService } from "../../services/EmployeeEvaluationsService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { QueryOptions } from "../../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../../lib/components/tag/Tag";
import axios from "axios";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useDebounce } from "../../../../lib/hooks/useDebounce";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { EmployeeBonusService } from "../../services/EmployeeBonusService";
import { EmployeeBonusResponseDTO } from "../../models/dtos/EmployeeBonusReponseDTO";
import { EmployeeBonusEvaluationSummaryDTO } from "../../models/dtos/EmployeeBonusEvaluationSummaryDTO";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { finalBonusScoreFromPercentage } from "../../../../common/helpers/finalBonusScoreFromPercentage";

var svc = new EmployeeBonusService();

function FinalScoreFromPercentage(percentage: number | undefined): number | undefined {

    if (percentage === undefined) { return undefined; }
    if (percentage < 26) {
        return 1;
    } else if (percentage < 51) {
        return 2;
    } else if (percentage < 71) {
        return 3;
    } else if (percentage < 90) {
        return 4;
    }
    return 5;
}

export function EmployeeBonusPage() {

    const navigate = useNavigate();
    const windowResize = useWindowResize();
    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { employeeId } = useParams();

    const [employeeBonus, setEmployeeBonus] = useState<EmployeeBonusResponseDTO>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    var searchFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });

    const [searchWord, setSearchWord] = useState<string>();
    const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);

    const getEmployeeBonusEvaluationsCall = useServiceCallPro(svc.getEmployeeBonusEvaluations);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployeeBonusEvaluations = useCallback(() => {

        if (!locationIDAsString || !employeeId) return;


        getEmployeeBonusEvaluationsCall.invoke(locationIDAsString, employeeId, { searchWord: searchWord, page: currentpage, pageLength: itemsPerPage })
            .then((data) => {
                if (!data) return;
                setEmployeeBonus(data);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error ?? "-"}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, searchWord, currentpage, itemsPerPage, getEmployeeBonusEvaluationsCall.invoke, setEmployeeBonus, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {

        getEmployeeBonusEvaluations();

    }, [currentpage, itemsPerPage, searchWord]);

    useEffect(() => {
        setCurrentPage(0);
        setSearchWord(debouncedSearchTerm);
    }, [debouncedSearchTerm]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleEvaluationPeriodDetailsClicked = useCallback((bonusEvaluationId: number) => {
        navigate(`${bonusEvaluationId}/details`);
    }, [navigate]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeBonusEvaluationSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.periodName,
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.EvaluationPeriod"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.periodStartDate ? DateTimeHelper.formatDate(v.periodStartDate) : "-",
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.StartDate"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.periodEndDate ? DateTimeHelper.formatDate(v.periodEndDate) : "-",
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.EndDate"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => <Tag
                    text={v.score ? "" + finalBonusScoreFromPercentage(v.score * 100) : "-"}
                    isTiny={windowResize < 1024}
                />,
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.Evaluation"),
                width: "110px"
            },
        ], [windowResize]);


    return (
        <PageLayout
            tabTitle={translate("EMPLOYEES.BONUS.Bonus")}
            pageTitle={translate("EMPLOYEES.BONUS.Bonus")}
            className="employee-bonus"
        /*   showBreadcrumb */
        >
            <FormFieldTextInput
                formControl={searchFormControl}
                icon={<SearchIcon />}
                placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                className="search-box"
            />

            <ResponsiveDataTable
                columnDefinitions={columns}
                items={employeeBonus?.records || []}
                totalitems={employeeBonus?.totalItems || 0}
                paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 15, 20] }}
                currentpage={currentpage}
                isLoading={getEmployeeBonusEvaluationsCall.isLoading}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                onClickRow={(bonus) => handleEvaluationPeriodDetailsClicked(bonus.bonusEvaluationId)}
            />
        </PageLayout>

    );
}
