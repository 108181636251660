import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { EmployeeActionItemsPage } from "../features/employees/pages/items/EmployeeActionItemsPage";
import { EmployeeBonusDetailsPage } from "../features/employees/pages/bonus/EmployeeBonusDetailsPage";
import { EmployeeBonusPage } from "../features/employees/pages/bonus/EmployeeBonusPage";
import { EmployeeCertificationsPage } from "../features/employees/pages/certifications/EmployeeCertificationsPage";
import { EmployeeEvaluationDetailPage } from "../features/employees/pages/evaluations/employee-evaluation-detail-page/EmployeeEvaluationDetailPage";
import { EmployeeEvaluationsPage } from "../features/employees/pages/evaluations/EmployeeEvaluationsPage";
import { EmployeeProgressionLadderPage } from "../features/employees/pages/career/EmployeeProgressionLadderPage";
import { EmployeesContainer } from "../common/layouts/content/EmployeesContainer";
import { EmployeesPage } from "../features/employees/pages/EmployeesPage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from "../models/api/gate/Permission";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";

const moduleDefinition: ModuleDefinition = {
    basePath: "/employees",
    routes: [
        {
            name: () => translate("EMPLOYEES.Employees"),
            pathPattern: "/",
        },
        {
            name: () => "##EMPLOYEE##",
            pathPattern: "/:employeeId",
        },
        {
            name: () => translate("EMPLOYEES.CAREER.CareerLadder"),
            pathPattern: "/:employeeId/career",
        },
        {
            name: () => translate("EMPLOYEES.EVALUATIONS.Evaluations"),
            pathPattern: "/:employeeId/evaluations",
        },
        {
            name: () => "##EVALUATIONID##",
            pathPattern: "/:employeeId/evaluations/:evaluationId",
        },
        {
            name: () => translate("EMPLOYEES.EVALUATIONS.Evaluation"),
            pathPattern: "/:employeeId/evaluations/:evaluationId/details",
        },
        {
            name: () => translate("EMPLOYEES.BONUS.Bonus"),
            pathPattern: "/:employeeId/bonus",
        },
        {
            name: () => translate("EMPLOYEES.BONUS.Bonus"),
            pathPattern: "/:employeeId/bonus/:periodId/details",
        },
        {
            name: () => translate("EMPLOYEES.CERTIFICATIONS.Certifications"),
            pathPattern: "/:employeeId/certifications",
        },
        {
            name: () => translate("EMPLOYEES.ITEMS.FollowUpItems"),
            pathPattern: "/:employeeId/items",
        },
    ],
};


export const humanResourcePermissions = [
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const adminPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const basePermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER
];


export function EmployeesModule() {
    const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

    useEffect(() => {
        registerBreadcrumbRoutes(moduleDefinition);
    }, []);


    return (
        <RouteGuard permissions={basePermissions}>
            <Routes>
                <Route path="/" element={<EmployeesPage />} />
                <Route path="employees" element={<EmployeesPage />} />
                <Route
                    path=":employeeId"
                    element={
                        <EmployeesContainer>
                            <Outlet />
                        </EmployeesContainer>}
                >
                    <Route index element={<Navigate to={"evaluations"} />} />

                    <Route path="career" element={<EmployeeProgressionLadderPage />} />
                    <Route path="evaluations" element={<EmployeeEvaluationsPage />} />
                    <Route path="evaluations/:evaluationId" element={<EmployeeEvaluationDetailPage />} />
                    <Route path="bonus" element={<EmployeeBonusPage />} />
                    <Route path="bonus/:periodId/details" element={<EmployeeBonusDetailsPage />} />
                    <Route path="certifications" element={<EmployeeCertificationsPage />} />
                    <Route path="items" element={<EmployeeActionItemsPage />} />
                </Route>
            </Routes>
        </RouteGuard>
    );
}