import { Navigate, Outlet, Route, Routes, } from "react-router-dom";
import { CollaboratorContainer } from "../common/layouts/content/CollaboratorContainer";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { MyActionItemsPage } from "../features/me/pages/items/MyActionItemsPage";
import { MyCertificationsPage } from "../features/me/pages/certifications/MyCertificationsPage";
import { MyEvaluationsPage } from "../features/me/pages/evaluations/MyEvaluationsPage";
import { MyProgressionLadderPage } from '../features/me/pages/career/MyProgressionLadderPage';
import { Permission } from "../models/api/gate/Permission";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";
import { MyEvaluationDetailsPage } from "../features/me/pages/evaluations/MyEvaluationDetailsPage";

const moduleDefinition: ModuleDefinition = {
    basePath: "/me",
    routes: [
        {
            name: () => translate("COMMMON.Profile"),
            pathPattern: "/",
        },
        {
            name: () => translate("EMPLOYEES.CAREER.CareerLadder"),
            pathPattern: "/careerladder",
        },
        {
            name: () => translate("EMPLOYEES.EVALUATIONS.EvaluationPeriods"),
            pathPattern: "/evaluations",
        },
        {
            name: () => "##EVALUATIONPERIOD##",
            pathPattern: "/evaluations/:evaluationId",
        },
        {
            name: () => translate("EMPLOYEES.EVALUATIONS.Evaluation"),
            pathPattern: "/evaluations/:evaluationId/details",
        },
        {
            name: () => translate("EMPLOYEES.CERTIFICATIONS.Certifications"),
            pathPattern: "/certifications",
        },
        {
            name: () => translate("EMPLOYEES.ITEMS.FollowUpItems"),
            pathPattern: "/items",
        },
    ],
};


const permissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER,
    Permission.LOCATION_ACCESS
];


export function CollaboratorModule() {

    const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

    useEffect(() => {
        registerBreadcrumbRoutes(moduleDefinition);
    }, []);

    return (
        <RouteGuard permissions={permissions} >
            <Routes>
                <Route
                    path="/"
                    element={
                        <CollaboratorContainer>
                            <Outlet />
                        </CollaboratorContainer>}
                >
                    <Route index element={<Navigate to={"evaluations"} />} />

                    <Route path="careerladder" element={<MyProgressionLadderPage />} />
                    <Route path="evaluations" element={<MyEvaluationsPage />} />
                    <Route path="evaluations/:evaluationId" element={<MyEvaluationDetailsPage />} />
                    <Route path="certifications" element={<MyCertificationsPage />} />
                    <Route path="items" element={<MyActionItemsPage />} />
                </Route>
            </Routes>
        </RouteGuard >
    );
}