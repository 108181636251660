import "./EvaluationPage.css";

import { EvaluationCategory, EvaluationSkill, SkillsCatEvaluationsCard } from "../components/SkillsCatEvaluationsCardV2";
import { EvaluationState, EvaluationStatusTranslationTerms } from "../../models/EvaluationStatus";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { BonusEvaluationCard } from "../components/BonusEvaluationCard";
import { CalibrateEvaluationPopup } from "../components/CalibrateEvaluationPopup";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { CertificatesEvaluationCard } from "../components/CertificatesEvaluationCard";
import { ReactComponent as CheckIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { ChecklistEvaluationCard } from "../components/ChecklistEvaluationCard";
import { ReactComponent as CommentsIcon } from "../../../../lib/assets/icons/comments.svg";
import { ConcludeEvaluationPopup } from "../components/ConcludeEvaluationPopup";
import { ConsultEvaluationHistoryPopup } from "../popups/ConsultEvaluationHistoryPopup";
import { EmployeeActionItemSummaryDTO } from "../../../employees/models/dtos/EmployeeActionItemSummaryDTO";
import { EmployeeBonusResponseDTO } from "../../../employees/models/dtos/EmployeeBonusReponseDTO";
import { EmployeeCertificationsService } from "../../../employees/services/EmployeeCertificationsService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationCertificateDTO } from "../../models/EvaluationCertificateDTO";
import { EvaluationChecklistDTO } from "../../models/EvaluationChecklistDTO";
import { EvaluationCommentsPopup } from "../popups/EvaluationCommentsPopup";
import { EvaluationDetailsDTO_V2 } from "../../models/EvaluationDetailsDTO_V2";
import { EvaluationMatrixResponseDTO } from "../../models/EvaluationMatrixResponseDTO";
import { EvaluationsService } from "../../services/EvaluationsService";
import { FollowUpEvaluationCard } from "../components/FollowUpEvaluationCard";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as HistoryIcon } from "../../../../lib/assets/icons/history.svg";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../../models/api/gate/Permission";
import { RejectEvaluationPopup } from "../components/RejectEvaluationPopup";
import { ReactComponent as RejectIcon } from "../../../../lib/assets/icons/reject.svg";
import { ResponsiveButton } from "../../../../lib/components/buttons/ResponsiveButton";
import { ReactComponent as RevertIcon } from "../../../../lib/assets/icons/revert.svg";
import { ReactComponent as SaveIcon } from "../../../../lib/assets/icons/save.svg";
import { StatusCard } from "../../../../lib/components/cards/stats-card/StatusCard";
import { ReactComponent as SuggestNextRoleIcon } from "../../../../lib/assets/icons/career-ladder.svg";
import { SuggestNextRolePopup } from "../popups/SuggestNextRolePopup";
import { Tag } from "../../../../lib/components/tag/Tag";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { UpdateEvaluationRequestDTO } from "../../models/UpdateEvaluationRequestDTO";
import { getEvaluationStateCss } from "../../../../common/helpers/getEvaluationStateCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

export interface NextRoleSkillLevelsDTO {
    skillId: number;
    competenceLevel: string;
}
export interface NextRoleDetailsDTO {
    roleId: number;
    roleDescription: string;
    skills: NextRoleSkillLevelsDTO[];
}


var svc = new EvaluationsService();
var employeeCertificationsSvc = new EmployeeCertificationsService();

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EvaluationPage() {

    const openPopup = usePopup();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { id: businessLineID, periodId: evaluationPeriodID, matrixId, evaluationId, employeeId } = useParams();
    const createNotification = useCreateNotification();

    const { isLoadingPermissions: loadingTopPermissions, hasPermission: hasTopPermissions } = useHasPermissions(topPermissions);

    const getEvaluationDetailsByIdV2Call = useServiceCallPro(svc.getEvaluationDetailsById_V2);
    const getEvaluationMatrixCall = useServiceCallPro(svc.getEvaluationMatrix);

    const getEvaluationFollowUpItemsCall = useServiceCallPro(svc.getEvaluationActionItems);
    const getUnjustifiedAbsencesCall = useServiceCallPro(svc.getUnjustifiedAbsences);
    const updateEvaluationDetailsCall = useServiceCallPro(svc.updateEvaluationDetails_V2);
    const updateEvaluationStatusCall = useServiceCallPro(svc.updateEvaluationStatus);

    const getEmployeeCertificationsCall = useServiceCallPro(employeeCertificationsSvc.getEmployeeCertifications);
    const getEvaluationBonusCall = useServiceCallPro(svc.getEvaluationBonus);


    const getNextRoleDetailsCall = useServiceCallPro(svc.getNextRoleDetails);

    const [evaluationDetails, setEvaluationDetails] = useState<EvaluationDetailsDTO_V2>();
    const [evaluationMatrixDetails, setEvaluationMatrixDetails] = useState<EvaluationMatrixResponseDTO>();

    const [evaluationTotal, setEvaluationTotal] = useState<number>(0);
    const [nextRoleTotal, setNextRoleTotal] = useState<number>(0);
    const [unjustifiedAbsences, setUnjustifiedAbsences] = useState<string>();
    const [nextRoleDetails, setNextRoleDetails] = useState<NextRoleDetailsDTO>();
    const [nextRoleId, setNextRoleId] = useState<number>();
    const [evaluationBonus, setEvalutionBonus] = useState<EmployeeBonusResponseDTO>();

    const [evaluationCategories, setEvaluationCategories] = useState<EvaluationCategory[]>([]);
    const [evaluationChecklistItems, setEvaluationChecklistItems] = useState<EvaluationChecklistDTO[]>([]);
    const [lastEmployeeCertifications, setLastEmployeeCertifications] = useState<EvaluationCertificateDTO[]>([]);


    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [followUpItems, setFollowUpItems] = useState<EmployeeActionItemSummaryDTO[]>([]);

    const feedbackManagerFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: true,
    });
    const feedbackEmployeeFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: true,
    });


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationDetailsById = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getEvaluationDetailsByIdV2Call.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationDetails(data);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, getEvaluationDetailsByIdV2Call.invoke, openPopup, feedbackEmployeeFormControl.setValue, feedbackManagerFormControl.setValue]);


    const getEvaluationMatrix = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getEvaluationMatrixCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationMatrixDetails(data);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });
    }, [businessLineID, evaluationId, evaluationPeriodID, getEvaluationMatrixCall, locationIDAsString, matrixId, openPopup])



    const getUnjustifiedAbsences = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getUnjustifiedAbsencesCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, evaluationId)
            .then((res) => {
                if (!res) return;
                setUnjustifiedAbsences(`${res.absenceDays}` || "");
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, getUnjustifiedAbsencesCall.invoke, openPopup]);


    const getEvaluationFollowUpItems = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getEvaluationFollowUpItemsCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId)
            .then((res) => {
                if (!res) return;
                setFollowUpItems(res);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response?.data?.message ?? ""}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, getEvaluationFollowUpItemsCall.invoke, openPopup]);


    const getNextRoleDetails = useCallback((nextRoleId: number) => {
        if (!locationIDAsString || !businessLineID || !matrixId || !matrixId || !nextRoleId) return;

        getNextRoleDetailsCall.invoke(locationIDAsString, businessLineID, matrixId, "" + nextRoleId)
            .then((res) => {
                if (!res) return;
                setNextRoleDetails(res);
                const total = res.skills.map(skill => parseInt(skill.competenceLevel)).reduce((sum, level) => sum + level, 0);
                setNextRoleTotal(total)
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, matrixId, getEvaluationFollowUpItemsCall.invoke, openPopup]);



    const updateEvaluationStatus = useCallback((state: EvaluationState, reason: string, notificationMessage: string) => {
        if (!businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        updateEvaluationStatusCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, {
            nextStatus: state,
            statusReason: reason
        })
            .then((res) => {
                setEvaluationDetails(res);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={notificationMessage}
                    />
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });
    }, [businessLineID, evaluationPeriodID, matrixId, evaluationId, evaluationDetails?.permissions?.canConfirm, isDirty, updateEvaluationStatusCall, createNotification, openPopup])




    const getEmployeeCertifications = useCallback(() => {

        if (!evaluationDetails?.targetEmployee.userEmployeeID) return;


        getEmployeeCertificationsCall.invoke(locationIDAsString, evaluationDetails?.targetEmployee.userEmployeeID, { page: 0, pageLength: 2 }, undefined)
            .then((response) => {

                if (response?.totalItems === 0) return;

                const mappedCertifications = response?.employeeCertifications.map((c) => {

                    var cert: EvaluationCertificateDTO = {
                        id: c.employeeCertificationId,
                        certificateName: c.certificationName,
                        startDate: c.startDate ?? null,
                        endDate: c.endDate ?? null,
                        state: c.state
                    };

                    return cert;
                });

                setLastEmployeeCertifications(mappedCertifications || []);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });

    }, [locationIDAsString, evaluationDetails?.targetEmployee.userEmployeeID, getEmployeeCertificationsCall.invoke, openPopup]);



    const getEvaluationBonus = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId) return;

        getEvaluationBonusCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId)
            .then((data) => {
                if (!data) return;
                setEvalutionBonus(data);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });
    }, [getEvaluationBonusCall.invoke, openPopup, locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const isEditionMode = useMemo(() => {
        if (!evaluationDetails || !businessLineID) {
            return false;
        }

        return evaluationDetails.permissions?.canEdit || false;

    }, [businessLineID, evaluationDetails?.permissions?.canEdit]);



    const isNextRole = useMemo(() => {
        return nextRoleId !== undefined;
    }, [nextRoleId]);




    const checklistSumValue = useMemo(() => {
        if (!evaluationChecklistItems || evaluationMatrixDetails?.evaluationMatrixChecklistItems === undefined) {
            return "-";
        }

        return `${evaluationChecklistItems.filter(item => item.isChecked).length}/${evaluationMatrixDetails?.evaluationMatrixChecklistItems.length}`;

    }, [evaluationChecklistItems, evaluationMatrixDetails?.evaluationMatrixChecklistItems]);


    useEffect(() => {
        getEvaluationDetailsById();
        getUnjustifiedAbsences();
        getEvaluationFollowUpItems();
        getEvaluationMatrix();
        getEvaluationBonus();
    }, [businessLineID, employeeId, evaluationId]);





    useEffect(() => {
        if (evaluationDetails?.targetEmployee.userEmployeeID) {
            getEmployeeCertifications();
        }
    }, [evaluationDetails?.targetEmployee.userEmployeeID]);



    useEffect(() => {
        if (!evaluationDetails) {
            return;
        }

        feedbackManagerFormControl.setValue(evaluationDetails.feedbackFromManager || "");
        feedbackEmployeeFormControl.setValue(evaluationDetails.feedbackFromEmployee || "");
        setNextRoleId(evaluationDetails.nextRoleRecommendationRoleId || undefined)

    }, [evaluationDetails]);



    useEffect(() => {
        if (!evaluationCategories) return

        var sum = evaluationCategories.flatMap(ev => ev.skills).reduce((accumulator, currentObj) => {
            if (!currentObj.value) return accumulator;

            return accumulator + parseInt(currentObj.value);
        }, 0);

        setEvaluationTotal(sum);

    }, [evaluationCategories]);



    useEffect(() => {
        feedbackEmployeeFormControl.setIsDisabled(!isEditionMode);
        feedbackManagerFormControl.setIsDisabled(!isEditionMode);
    }, [isEditionMode]);



    useEffect(() => {
        if (!nextRoleId) return;
        getNextRoleDetails(nextRoleId)
    }, [nextRoleId]);



    useEffect(() => {
        if (!evaluationDetails) return;

        if (evaluationDetails?.feedbackFromEmployee !== feedbackEmployeeFormControl.value ||
            evaluationDetails?.feedbackFromManager !== feedbackManagerFormControl.value) {
            setIsDirty(true);
        }
    }, [feedbackEmployeeFormControl.value, feedbackManagerFormControl.value])



    useEffect(() => {

        if (!evaluationDetails || !evaluationMatrixDetails) return;

        var evaluationCategoriesMapped: EvaluationCategory[] = [];

        for (var category of evaluationMatrixDetails.skillCategoryDetails) {

            var evaluationCategory: EvaluationCategory = {
                name: category.name,
                skills: category.skills.map((skill) => {

                    const evaluationMatrixEntry = evaluationMatrixDetails.evaluationMatrixEntries.find(entry => entry.skillId === skill.skillID);
                    const evaluationValue = evaluationDetails.evaluationValues.find(evaValue => evaValue.targetEvaluationMatrixEntryId === evaluationMatrixEntry?.id);

                    const mSkill: EvaluationSkill = {
                        isValid: true,
                        name: skill.name,
                        skillId: skill.skillID,
                        // skill is Disabled???
                        targetEvaluationMatrixEntryId: evaluationMatrixEntry?.id || 0,
                        competenceLevel: evaluationMatrixEntry?.competenceLevel ?? null,
                        value: evaluationValue?.value !== undefined ? `${evaluationValue?.value}` : undefined
                    };

                    return mSkill;
                })
            }

            evaluationCategoriesMapped.push(evaluationCategory)
        }

        setEvaluationCategories(evaluationCategoriesMapped)

    }, [evaluationDetails?.evaluationValues, evaluationMatrixDetails])



    useEffect(() => {

        if (!evaluationDetails?.evaluationChecklistItems || !evaluationMatrixDetails?.evaluationMatrixChecklistItems) return;

        const mappedItems = evaluationMatrixDetails?.evaluationMatrixChecklistItems.map((matrixItem, idx) => {
            const matchedItem = evaluationDetails?.evaluationChecklistItems.find(
                ({ targetChecklistItemId }) => targetChecklistItemId === matrixItem.evaluationMatrixChecklistItemId
            );

            return {
                targetMatrixChecklistItemId: matrixItem.evaluationMatrixChecklistItemId,
                checklistItemDescription: matrixItem.description,
                isChecked: matchedItem?.isChecked ?? false,
                id: idx
            };
        });

        setEvaluationChecklistItems(mappedItems);

    }, [evaluationDetails?.evaluationValues, evaluationMatrixDetails?.evaluationMatrixChecklistItems])





    /****************************
     * USER ACTIONS
     *****************************/


    const updateChecklistItems = useCallback((checklist: EvaluationChecklistDTO[]) => {
        setIsDirty(true);
        setEvaluationChecklistItems(checklist);
    }, [setEvaluationChecklistItems, setIsDirty]);


    const handleFollowUpItemsChanged = useCallback((newFollowUpItems: EmployeeActionItemSummaryDTO[]) => {
        setFollowUpItems(newFollowUpItems)
    }, [setFollowUpItems]);



    const handleSaveClicked = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID || !matrixId || !evaluationId || !evaluationDetails || !followUpItems) return;

        const flattenSkills = evaluationCategories.flatMap(f => f.skills);

        if (flattenSkills.some(skill => skill.isValid === false)) {
            openPopup(<ErrorPopup>{translate("BUSINESSLINES.EVALUATIONS.ERRORS.InputFieldInvalid")}</ErrorPopup>);
            return;
        }

        let requestDetailsDto: UpdateEvaluationRequestDTO = {
            evaluationValues: flattenSkills.filter(skill => skill.value)
                .map(skill => ({
                    targetEvaluationMatrixEntryId: skill.targetEvaluationMatrixEntryId,
                    value: parseInt(skill.value ?? "") //this will never be an invalid value because inValid prop would catch it 
                })),
            checklistItemValues: evaluationChecklistItems
                .map((item) => ({
                    evaluationChecklistItemId: item.targetMatrixChecklistItemId,
                    isApproved: item.isChecked
                })),
            feedbackFromEmployee: feedbackEmployeeFormControl.value || "",
            feedbackFromManager: feedbackManagerFormControl.value || "",
            nextRoleId: nextRoleId || null
        };

        updateEvaluationDetailsCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, requestDetailsDto)
            .then((res) => {
                setEvaluationDetails(res);
                console.log("res", res);
                setIsDirty(false);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("BUSINESSLINES.EVALUATIONS.NOTIFICATIONS.UpdateEvaluationDetailsSuccess")}
                    />
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error?.response?.data?.message ? error?.response?.data?.message : translate("BUSINESSLINES.EVALUATIONS.ERRORS.ErrorSavingEvaluationDetails")}</ErrorPopup>);
            })
    }, [locationIDAsString, businessLineID, evaluationPeriodID, matrixId, evaluationId, evaluationChecklistItems, evaluationDetails, followUpItems, evaluationCategories, feedbackEmployeeFormControl.value, feedbackManagerFormControl.value, openPopup, createNotification, updateEvaluationDetailsCall.invoke, nextRoleDetails?.roleId]);




    const handleNextRoleSelected = useCallback((nextRoleId: number) => {
        if (!evaluationDetails) return;
        setIsDirty(true);
        setNextRoleId(nextRoleId)
    }, [evaluationDetails, getNextRoleDetails]);




    const handleSuggestNextRole = useCallback(() => {
        if (!evaluationDetails) return;
        openPopup(<SuggestNextRolePopup
            locationId={locationIDAsString}
            businessLineId={"" + businessLineID}
            currentRoleId={evaluationDetails.roleId || 0}
            onCompletedOperations={handleNextRoleSelected} />)
    }, [locationIDAsString, businessLineID, evaluationDetails, openPopup, handleNextRoleSelected]);



    const handleEvaluationSkillChanged = useCallback((values: EvaluationCategory[]) => {
        setIsDirty(true);
        setEvaluationCategories(values)
    }, [locationIDAsString, businessLineID, evaluationDetails, openPopup, handleNextRoleSelected]);




    const handleHistoryClicked = useCallback(() => {
        openPopup(
            <ConsultEvaluationHistoryPopup locationId={locationIDAsString}
                businessLineId={"" + businessLineID} periodId={"" + evaluationPeriodID}
                matrixId={"" + matrixId} evaluationId={"" + evaluationId} />
        )
    }, [businessLineID, evaluationId, evaluationPeriodID, locationIDAsString, matrixId, openPopup])



    const handleCommentsClicked = useCallback(() => {
        openPopup(
            <EvaluationCommentsPopup locationId={locationIDAsString}
                businessLineId={"" + businessLineID} periodId={"" + evaluationPeriodID}
                matrixId={"" + matrixId} evaluationId={"" + evaluationId}
                isEvaluationStateClosed={evaluationDetails?.state === EvaluationState.CONCLUDED || evaluationDetails?.state === EvaluationState.CANCELED} />
        );
    }, [businessLineID, evaluationDetails?.state, evaluationId, evaluationPeriodID, locationIDAsString, matrixId, openPopup])



    const handleConfirmClicked = useCallback(() => {
        if (!evaluationDetails?.permissions?.canConfirm || isDirty) return;

        updateEvaluationStatus(EvaluationState.CONFIRMED, "", translate("BUSINESSLINES.EVALUATIONS.NOTIFICATIONS.ConfirmEvaluationSuccessMessage"));
    }, [!evaluationDetails?.permissions?.canConfirm, isDirty, translate, updateEvaluationStatus]);




    const handleRevertClicked = useCallback(() => {
        if (!evaluationDetails?.permissions?.canRevertConfirmation) return;

        updateEvaluationStatus(EvaluationState.IN_PROGRESS, "", translate("BUSINESSLINES.EVALUATIONS.NOTIFICATIONS.RevertEvaluationDetailsSucess"));
    }, [!evaluationDetails?.permissions?.canRevertConfirmation, isDirty, translate, updateEvaluationStatus]);




    const handleRejectClicked = useCallback(() => {
        if (!evaluationDetails?.permissions?.canReject/*  || !hasTopPermissions */) return;

        openPopup(<RejectEvaluationPopup
            locationId={locationIDAsString}
            businessLineID={businessLineID}
            evaluationPeriodID={evaluationPeriodID}
            matrixId={matrixId}
            evaluationId={evaluationId}
            employeeName={evaluationDetails.targetEmployee.userDisplayName}
            onRejectEvaluation={setEvaluationDetails}
        />)
    }, [locationIDAsString, businessLineID,
        evaluationPeriodID, matrixId,
        evaluationId, evaluationDetails?.targetEmployee.userDisplayName,
        setEvaluationDetails, !evaluationDetails?.permissions?.canReject, hasTopPermissions]);



    const handleCalibratedClicked = useCallback(() => {
        if (!evaluationDetails?.permissions?.canReject /* || !hasTopPermissions */) return;

        openPopup(<CalibrateEvaluationPopup
            locationId={locationIDAsString}
            businessLineID={businessLineID}
            evaluationPeriodID={evaluationPeriodID}
            matrixId={matrixId}
            evaluationId={evaluationId}
            employeeName={evaluationDetails.targetEmployee.userDisplayName}
            onCalibrateEvaluation={setEvaluationDetails}
        />)
    }, [locationIDAsString, businessLineID,
        evaluationPeriodID, matrixId,
        evaluationId, evaluationDetails?.targetEmployee.userDisplayName,
        setEvaluationDetails, !evaluationDetails?.permissions?.canReject,
        hasTopPermissions]);



    const handleConcludeClicked = useCallback(() => {
        if (!evaluationDetails?.permissions?.canClose || loadingTopPermissions || !hasTopPermissions) return;

        openPopup(<ConcludeEvaluationPopup
            locationId={locationIDAsString}
            businessLineID={businessLineID}
            evaluationPeriodID={evaluationPeriodID}
            matrixId={matrixId}
            evaluationId={evaluationId}
            employeeName={evaluationDetails.targetEmployee.userDisplayName}
            onConcludeEvaluation={setEvaluationDetails}
        />)
    }, [locationIDAsString, businessLineID,
        evaluationPeriodID, evaluationDetails?.permissions?.canClose,
        matrixId, evaluationId,
        evaluationDetails?.targetEmployee.userDisplayName,
        setEvaluationDetails, !evaluationDetails?.permissions?.canReject,
        hasTopPermissions, loadingTopPermissions]);



    /****************************
     * CSS & HTML
     *****************************/
    const renderPageActionsBtns = useMemo(() => {
        if (!evaluationDetails?.state || !businessLineID) return;
        if (loadingTopPermissions) return;

        /* if (loadingTopPermissions || !hasTopPermissions) return; */
        // Has top permissions não vai deixar managers avaliar

        const saveBtn: React.ReactNode = <ResponsiveButton type="secondary" icon={<SaveIcon />} text={translate("COMMON.Save")} onClick={handleSaveClicked} />;
        const suggestNextRoleBtn: React.ReactNode = <ResponsiveButton type="secondary" icon={<SuggestNextRoleIcon />} text={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.SuggestNextRole")} onClick={handleSuggestNextRole} />;

        const confirmBtn = (<ResponsiveButton type="primary" icon={<CheckIcon />} text={translate("COMMON.Confirm")} onClick={handleConfirmClicked} />);
        const revertBtn = (<ResponsiveButton type="tertiary" icon={<RevertIcon />} text={translate("COMMON.Revert")} onClick={handleRevertClicked} />)
        const rejectBtn = (<ResponsiveButton className="reject-btn" type="primary" icon={<RejectIcon />} text={translate("COMMON.Reject")} onClick={handleRejectClicked} />);
        const calibrateBtn = (<ResponsiveButton type="primary" icon={<CheckIcon />} text={translate("COMMON.Calibrate")} onClick={handleCalibratedClicked} />);
        const concludeBtn = (<ResponsiveButton type="primary" icon={<CheckIcon />} text={translate("COMMON.Conclude")} onClick={handleConcludeClicked} />);

        const historyBtn: React.ReactNode = <ResponsiveButton type="secondary" showButtonIcon={true} icon={<HistoryIcon />} text={""/* translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.History") */} onClick={handleHistoryClicked} />;
        const commentsBtn: React.ReactNode = <ResponsiveButton type="secondary" showButtonIcon={true} icon={<CommentsIcon />} text={""/* translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.COMMENTS.Comments") */} onClick={handleCommentsClicked} />;

        switch (evaluationDetails.state) {
            case EvaluationState.READY_TO_EVALUATE:
                return <>
                    {commentsBtn}
                    {historyBtn}
                    {!isNextRole && suggestNextRoleBtn}
                    {isDirty && saveBtn}
                </>;
            case EvaluationState.IN_PROGRESS:
            case EvaluationState.REJECTED:
                return <>
                    {commentsBtn}
                    {historyBtn}
                    {!isNextRole && suggestNextRoleBtn}
                    {evaluationDetails.permissions?.canEdit && isDirty && saveBtn}
                    {evaluationDetails.permissions?.canConfirm && !isDirty && confirmBtn}
                </>;
            case EvaluationState.CONFIRMED:
                return <>
                    {commentsBtn}
                    {historyBtn}
                    {evaluationDetails.permissions?.canRevertConfirmation && revertBtn}
                    {hasTopPermissions || evaluationDetails.permissions?.canReject ? rejectBtn : null}
                    {hasTopPermissions || evaluationDetails.permissions?.canCalibrate ? calibrateBtn : null}
                </>;
            case EvaluationState.CALIBRATED:
                return <>
                    {commentsBtn}
                    {historyBtn}
                    {hasTopPermissions || evaluationDetails.permissions?.canReject ? rejectBtn : null}
                    {hasTopPermissions || evaluationDetails.permissions?.canClose ? concludeBtn : null}
                </>;
            case EvaluationState.CONCLUDED:
                return <>
                    {commentsBtn}
                    {historyBtn}  </>

            default:
                return null;
        }
    }, [evaluationDetails?.state,
    evaluationDetails?.permissions?.canEdit,
    evaluationDetails?.permissions?.canConfirm,
    evaluationDetails?.permissions?.canRevertConfirmation,
    evaluationDetails?.permissions?.canReject,
    evaluationDetails?.permissions?.canClose,
    evaluationDetails?.permissions?.canCalibrate,
        businessLineID,
        loadingTopPermissions,
        hasTopPermissions,
        isDirty,
        handleSuggestNextRole,
        handleSaveClicked,
        handleRevertClicked,
        handleHistoryClicked,
        handleRejectClicked,
        handleCommentsClicked,
        handleCalibratedClicked,
        handleConcludeClicked])



    const renderAdditionalInfoCards = useMemo(() => {
        if (businessLineID) {
            return <div className="additional-info">
                <BonusEvaluationCard isLoading={getEvaluationBonusCall.isLoading} items={evaluationBonus} />
                <StatusCard isLoading={getUnjustifiedAbsencesCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.UnjustifiedAbsences")} value={unjustifiedAbsences ?? "-"} backgroundColor="status-dark-grey" className="absences-status-card" />
                <CertificatesEvaluationCard employeeValmetId={evaluationDetails?.targetEmployee.userEmployeeID || ""} locationId={locationIDAsString} certificates={lastEmployeeCertifications || []} isLoading={getEvaluationDetailsByIdV2Call.isLoading && getEmployeeCertificationsCall.isLoading} />
            </div>
        }
    }, [getEvaluationBonusCall.isLoading, evaluationBonus, businessLineID, locationIDAsString, evaluationPeriodID, matrixId, evaluationId, getUnjustifiedAbsencesCall.isLoading, unjustifiedAbsences, lastEmployeeCertifications, getEvaluationDetailsByIdV2Call.isLoading, getEmployeeCertificationsCall.isLoading, evaluationDetails?.targetEmployee.userEmployeeID, lastEmployeeCertifications]);




    const renderChecklistCard = useMemo(() => {
        if (evaluationDetails?.evaluationChecklistItems && evaluationMatrixDetails?.evaluationMatrixChecklistItems) {
            return <ChecklistEvaluationCard
                isEditionModeEnabled={isEditionMode}
                items={evaluationChecklistItems || []}
                isLoading={getEvaluationDetailsByIdV2Call.isLoading || getEvaluationMatrixCall.isLoading}
                onChange={updateChecklistItems} />;
        }
    }, [evaluationDetails?.evaluationChecklistItems,
    evaluationMatrixDetails?.evaluationMatrixChecklistItems,
    getEvaluationDetailsByIdV2Call.isLoading,
    getEvaluationMatrixCall.isLoading,
        isEditionMode,
        evaluationChecklistItems,
        updateChecklistItems]);







    return (
        <PagePermissions permissions={basePermissions} >
            <PageLayout
                tabTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
                showBreadcrumb
                pageTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
                className="evaluation-details"
                actions={renderPageActionsBtns}
            >
                {updateEvaluationStatusCall.isLoading || updateEvaluationDetailsCall.isLoading ? <FullScreenLoader /> : null}

                <div className="evaluation-details-summary">
                    <TitledCardContainer className="employee-data" extraTitle={<Tag
                        text={evaluationDetails?.state ? translate(EvaluationStatusTranslationTerms[evaluationDetails.state]) : "-"}
                        backgroundColor={getEvaluationStateCss(evaluationDetails?.state ?? -1)} />} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.EmployeeData")} isLoading={getEvaluationDetailsByIdV2Call.isLoading}>
                        <FormSection unsetFlexGrow>

                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Name")}
                                value={evaluationDetails?.targetEmployee.userDisplayName || "-"} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Role")}
                                value={evaluationDetails?.roleName || "-"} />
                        </FormSection>
                    </TitledCardContainer>
                    <div className={isNextRole ? "evaluation-details-summary-cards-updated" : "evaluation-details-summary-cards"}>
                        <StatusCard isLoading={getEvaluationDetailsByIdV2Call.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Value")} value={evaluationTotal.toString()} backgroundColor="status-dark-grey" />
                        <StatusCard isLoading={getEvaluationDetailsByIdV2Call.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Checklist")} value={checklistSumValue ?? "-"} backgroundColor="status-dark-grey" />
                        {isNextRole && <StatusCard isLoading={getNextRoleDetailsCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.TotalNextRole")} value={"" + nextRoleTotal} backgroundColor="status-green" />}
                    </div>
                </div>

                {
                    evaluationDetails?.state === EvaluationState.REJECTED &&
                    <CardContainer className="evaluation-details-status-message" backgroundColor="status-red">
                        <div><b>{translate("COMMON.STATUS.Rejected")}</b>  {evaluationDetails?.statusReason}</div>
                    </CardContainer>
                }
                <div className="skills-categories">
                    <SkillsCatEvaluationsCard
                        isEditionModeEnabled={isEditionMode}
                        roleName={evaluationDetails?.roleName || ""}
                        businessLineId={businessLineID}
                        nextRoleDetails={nextRoleDetails}
                        OnSuggestNextRoleClicked={handleSuggestNextRole}
                        values={evaluationCategories}
                        OnEvaluationValueChanged={handleEvaluationSkillChanged}
                    />
                </div>


                {renderAdditionalInfoCards}
                {renderChecklistCard}
                <div className="feedback-cards">
                    <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackManager")}>
                        <FormFieldTextArea
                            formControl={feedbackManagerFormControl}
                            alternativeStyle
                        />
                    </TitledCardContainer>
                    <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackEmployee")}>
                        <FormFieldTextArea
                            formControl={feedbackEmployeeFormControl}
                            alternativeStyle
                        />
                    </TitledCardContainer>
                </div>
                <FollowUpEvaluationCard
                    locationdId={locationIDAsString}
                    businessLineId={businessLineID}
                    evaluationPeriodId={evaluationPeriodID}
                    matrixId={matrixId}
                    evaluationId={evaluationId}
                    items={followUpItems}
                    isLoading={getEvaluationFollowUpItemsCall.isLoading}
                    isEditionModeEnabled={isEditionMode}
                    onChange={handleFollowUpItemsChanged} />
            </PageLayout >
        </PagePermissions>
    );
}

