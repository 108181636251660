import { ILanguage } from "./ILanguage";

export const languageEN: ILanguage = {
  COMMON: {
    ProjectName: "Careers",
    Continue: "Continue",
    Yes: "Yes",
    No: "No",
    Cancel: "Cancel",
    Create: "Create",
    Add: "Add",
    Activate: "Activate",
    Import: "Import",
    Change: "Change",
    Suspend: "Suspend",
    Save: "Save",
    Edit: "Edit",
    Delete: "Delete",
    Remove: "Remove",
    Deactivate: "Deactivate",
    Logout: "Logout",
    Optional: "Optional",
    Registar: "Create",
    PrepositionForDateTime: " on ",
    CreatedBy: "Registed by",
    CreatedOn: "Registed on",
    All: "All",
    Clear: "Clear",
    Deliver: "Deliver",
    OtherActions: "Other actions",
    CloneAction: "Clone Sample",
    SelectOneOptionPlaceholder: "Select one option",
    Export: "Export",
    Weight: "Weight",
    Confirm: "Confirm",
    Name: "Name",
    Upload: "Upload",
    Status: "Status",
    SaveAll: "Save All",
    ImportCollaborators: "Import Collaborators",
    Saved: "Information changed.",
    Close: "Close",
    Enabled: "Enabled",
    Disabled: "Disabled",
    Next: "Next",
    DeleteCAPSLOCK: "Remove",
    Select: "Select",
    Conclude: "Conclude",
    Calibrate: "Calibrate",
    Reject: "Reject",
    Revert: "Revert",
    Reopen: "Reopen",
    AddAttachment: "Add Attachment",
    ChangeAttachment: "Change Attachment",
    Profile: "Profile",
    Collaborator: "Collaborator",
    ViewAll: "View All",
    RegisteredBy: "Registered by",

    STATUS: {
      Registered: "Registered",
      Rejected: "Rejected",
      Canceled: "Canceled",
      Confirmed: "Confirmed",
      Completed: "Completed",
      InProgress: "In Progress",
      ReadyToEvaluate: "Ready to Evaluate",
      Concluded: "Concluded",
      NotConcluded: "Not Concluded",
      Calibrated: "Calibrated",
      NotCompleted: "Not Completed",

    },

    POPUPS: {
      Download: "Download",
      ImportOptions: "Import Options",
      ExcelTemplate: "Excel Template",
      PdfTemplate: "PDF Template",
      ExcelTemplateText: "Click the button below to download an Excel template file. This template contains the correct structure for data import.",
      PdfTemplateText: "Click the button below to download an PDF template file. This is an example of performance appraisal guidance for team managers.",
      Import: "Import",
      ImportText: "Click the button below to select an Excel file and start the import process. Make sure you select the correct file.",
      ImportEvaluationAttachs: "Click the button below to import a file. This file will be displayed for all users with permission to access Evaluation's section.",
      SelectDate: "Select a date"
    },

    ERRORS: {
      UnknownError: "Unknown Error, please contact support team.",
      ErrorSavingValues: "There was a problem saving the data. Please verify unsaved changes."
    },

    FORM: {
      Details: "Details",
      Search: "Search",
      SearchPlaceholder: "Search",
      InitialDate: "Initial Date",
      InitialDatePlaceholder: "Initial Date",
      InitialCreationDatePlaceholder: "Initial Creation Date",
      FinalDate: "Final Date",
      FinalDatePlaceholder: "Final Date",
      FinalCreationDatePlaceholder: "Final Creation Date",
      RequestDatePlaceholder: "Select a date",
      DefaultPlaceholder: "Type Here",
      SelectDefaultPlaceholder: "Type to search",
      SelectStatusPlaceholder: "Select status",
      Filters: "Filters",
      CleanFilters: "Reset Filters",
      ClearSelection: "Clear selection",
      Sunday: "Su",
      Monday: "Mo",
      Tuesday: "Tu",
      Wednesday: "We",
      Thursday: "Th",
      Friday: "Fr",
      Saturday: "Sa",

      VALIDATIONS: {
        CannotBeEmpty: "This field cannot be empty.",
        RequiredField: "This field is required.",
        GuidValidator: "Invalid GUID format.",
        SelectOneOption: "Select an option",
        BatchFormatError: "Must contain the following format: ####### or #######/# or #######/##",
        FillMandatoryFields: "Please fill the mandatory fields",
        InvalidLocationId: "Location ID is invalid.",
        InvalidRequestId: "Analysis Request ID is invalid.",
        PositiveNumber: "Insert an integer number",
        PositiveNumberOrDecimal: "Insert an integer number / decimal",
        ZeroOrDecimalNumber: "Insert an integer/decimal number",
        SelectDate: "Please select a date",

      }
    },

    ERRORPAGES: {
      SomethingWrong: "Something Went Wrong",
      BackToHome: "Back to Home",
      AccessDenied: "Access Denied",
      AccessDeniedText1: "You do not have permission to view this page!",
      AccessDeniedText2: "Please check your credentials and try again.",
      PageNotFound: "Page Not Found",
      PageNotFoundText: "We can't seem to find the page you are looking for.",
      SessionExpired: "Session Expired",
      SessionExpiredText: "Your session has expired due to your inactivity.",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Error!",
      ErrorText: "",
      Success: "Success!",
      SuccessText: "",
      Warning: "Warning",
      WarningText: "",
    },

    TABLE: {
      NoResults: "No results found",
      NoResultsText: "We couldn't find what you're looking for.",/* 
      NoResultsText: "We couldn't find what you're looking for, please search again", */
      RowsPerPage: "Rows per page",
      Of: "of",
    }
  },

  BUSINESSLINES: {
    BusinessLine: "Business Line",
    BusinessLines: "Business Lines",

    INFOANDFORM: {
      Name: "Name",
    },

    EVALUATIONPERIODS: {
      EvaluationPeriods: "Evaluation Periods",
      EvaluationPeriodDetails: "Evaluation Period Details",
      EditEvaluationPeriod: "Edit Evaluation Period",
      CreatePeriod: "Create Period",

      COLLABORATORS: {
        ImportCollaborators: "Import Collaborators",

        POPUPS: {
          ImportCollaboratorsConfirmText: "Are you sure you pretend to import collaborators to the Evaluation Period?",
          InvalidImport: "{0} row(s) from the file were not imported due to invalid fields.",
          ImportSuccess: "Evaluation Period Collaborators imported."
        }
      },

      INFOANDFORM: {
        EvaluationPeriodData: "Evaluation Period Data",
        SubmittedEvaluations: "Submitted Evaluations",
        Name: "Name",
        NamePlaceholder: "Ex: 2024",
        StartDate: "Start Date",
        StartDatePlaceholder: "Select an Initial Date",
        EndDate: "End Date",
        EndDatePlaceholder: "Select a Final Date",
        Status: "Status",
        StartEvaluations: "Start Evaluations",
        EndEvaluations: "End Evaluations",
        StartCalibrations: "Start Calibrations",
        EndCalibrations: "End Calibrations",
        DatesToNotificateManagersByEmail: "Dates to notify Managers via e-mail",
        InitialDayToEvaluate: "First day to Evaluate",
        FinalDayToEvaluate: "Last day to Evaluate",
        InitialDayToCalibrate: "First day to Calibrate",
        FinalDayToCalibrate: "Last day to Calibrate",
      },

      POPUPS: {
        Cancel: "Cancel Period",
        CancelEvaluationPeriodConfirmText: "Are you sure you want to cancel the evaluation period?",
        CloseEvaluationPeriodConfirmText: "Are you sure you want to close the evaluation period?",
        ActivateEvaluationPeriodConfirmText: "Are you sure you want to activate the evaluation period?",
        ReopenEvaluationPeriodConfirmText: "Are you sure you want to reopen the evaluation period?",
      },

      MESSAGES: {
        CreatePeriodSuccess: "New Evaluation Period Created",
        EditPeriodSuccess: "Evaluation Period Edited",
        PeriodCanceledSuccess: "Evaluation Period Canceled",
        PeriodAtivatedSuccess: "Evaluation Period in Progress",
        PeriodCompletedSuccess: "Evaluation Period Completed",
        PeriodReopenSuccess: "Evaluation Period Reopened",
        CreatedCompanyCriteriaSuccess: "Company Factor created",
        MultipleCompanyCriteriasUpdatedSuccess: "Company Criterias Updated",
        MultipleEmployeeCriteriasUpdatedSuccess: "Employee Criterias Updated",
        ChangedCompanyCriteriaSuccess: "Company Factor changed",
        DeleteCompanyCriteriaSucess: "Company Factor deleted",
        CreatedEmployeeCriteriaSuccess: "Employee Factor created",
        ChangedEmployeeCriteriaSuccess: "Employee Factor changed",
        DeleteEmployeeCriteriaSucess: "Employee Factor deleted",
      },

      ABSENCES: {
        Absences: "Absences",
        AbsencesMap: "Absences Map",
        ImportAbsences: "Import Absences",
        

        INFOANDFORM: {
          EmployeeName: "Employee",
          AbsenceDays: "Absence Days",
          UnjustifiedAbsences: "Unjustified Absences",
        },

        POPUPS: {
          ImportAbsencesConfirmText: "Reimport 'Absences Map' will overwrite the existing data!",
          InvalidImport: "{0} row(s) from the file were not imported due to invalid absence days or missing employees.",
          ImportSuccess: "Absences Map imported."
        }
      }
    },

    EVALUATIONS: {
      Evaluation: "Evaluation",
      Evaluate: "Evaluate",

      INFOANDFORM: {
        EmployeeData: "Employee Data",
        Name: "Name",
        Role: "Role",
        Value: "Value",
        Checklist: "Checklist",
        Skill: "Skill",
        Evaluation: "Evaluation",
        Employee: "Employee",
        SuggestNextRole: "Suggest Next Role",
        History: "History",
        Bonus: "Bonus",
        Period: "Period",
        Certificates: "Certificates",
        CertificateName: "Name",
        CertificateDate: "Date",
        CertificateConclusionDate: "Conclusion Date",
        CertificateInitialDate: "Initial Date",
        CertificateApproval: "Approval",
        UnjustifiedAbsences: "Unjustified Absences",
        FeedbackManager: "Feedback Manager",
        FeedbackEmployee: "Feedback Employee",
        FollowUp: "Follow Up",
        FollowUpItem: "Item",
        FollowUpItemName: "Name",
        FollowUpItemNamePlaceholder: "Ex: Improve communication",
        FollowUpDescription: "Description",
        FollowUpDescriptionPlaceholder: "Type a description",
        FollowUpStatus: "Status",
        FollowUpStartDate: "Start Date",
        FollowUpStartDatePlaceholder: "Select a date",
        FollowUpConclusionDate: "Conclusion Date",
        FollowUpConclusionDatePlaceholder: "Select a date",
        Reason: "Justification",
        RejectReasonPlaceholder: "Type a reason for the rejection",
        AddFollowUpItem: "Add Item",

        AreaData: "Area Details",
        ColaboratorsToEvaluate: "Collaborators to Evaluate",
        TotalEvaluation: "Total Evaluation",
        TotalNextRole: "Total Next Role",
        State: "State"
      },

      COMMENTS: {
        Comment: "Comment",
        Comments: "Comments",
        AddComment: "Add Comment",
        ConsultComments: "Consult Comments",
        CommentPlaceholder: "Type your comment",
        NoComments: "There is no comments for this evaluation.",

        MESSAGES: {
          CommentAddedSuccess: "Comment added."
        },
      },


      HISTORY: {
        NoHistoryLines: "There is no history for this evaluation.",
        HistoryLineForUnknownAction: "Unknown action.",
        HistoryLineForSaveEvaluation: "Evaluation was saved.",
        HistoryLineForConfirmEvaluation: "Evaluation was confirmed.",
        HistoryLineForCloseEvaluation: "Evaluation was closed.",
        HistoryLineForCalibrateEvaluation: "Evaluation was calibrated.",
        HistoryLineForRevertEvaluation: "Evaluation was reverted.",
        HistoryLineForRejectEvaluation: "Evaluation was rejected with the following justification: {0}",
        HistoryLineForNextRoleRecomendation: "Evaluation was marked as a recommendation for promotion.",
        HistoryLineForRemoveNextRoleRecomendation: "Recommendation for promotion was removed.",
        HistoryLineForAddActionItem: "Follow Up Item was added.",
        HistoryLineForRemoveActionItem: "Follow Up Item was removed."
      },

      POPUPS: {
        DeleteFollowUpItemConfirmText: "Are you sure you want to delete the item?",
        NextRole: "Next Role",
        NextRolePopupText: "Select the next role in the employee's career ladder.",
        EmptyCriterias: "The selected function has no associated criteria.",
        RejectEvaluation: "Reject Evaluation",
        CalibrateEvaluation: "Calibrate Evaluation",
        ConcludeEvaluation: "Conclude Evaluation",
        MESSAGES: {
          ConcludeEvaluationWarningMessage: "Attention: this action will conclude the evaluation.",
          CalibrateEvaluationWarningMessage: "Attention: This action will calibrate the evaluation."
        },
      },

      ERRORS: {
        InputFieldInvalid: "Invalid values were found on competence level fields",
        ErrorSavingEvaluationDetails: "An error occurred while saving the evaluation details.",
        ErrorSavingFollowUpItems: "An error occurred while saving the follow up items.",
      },

      NOTIFICATIONS: {
        RevertEvaluationDetailsSucess: "Evaluation reverted.",
        UpdateEvaluationDetailsSuccess: "Evaluation saved.",
        ConfirmEvaluationSuccessMessage: "Evaluation confirmed",
        RejectEvaluationSuccessMessage: "Evaluation rejected",
        CalibrateEvaluationSuccessMessage: "Evaluation calibrated",
        ConcludeEvaluationSuccessMessage: "Evaluation completed"
      }
    },

    ROLES: {
      Roles: "Roles",
      CreateRole: "Create Role",
      RoleDetails: "Role Details",
      RoleData: "Role Data",

      INFOANDFORM: {
        Role: "Role",
        JobPositions: "Job Positions",
        JobGrade: "Job Grade",
        JobGradePlaceholder: "Ex: 5",
        RoleReferenceValue: "Role Value Reference",
        Name: "Name",
        NamePlaceholder: "Ex: Operador de Logística",
      },

      POPUP: {
        CreateRole: "Create Role",
        CreateRoleSuccess: "Role created!",
        EditRole: "Edit Role",
        EditRoleSuccess: "Role edited!",
        LoadingRoleError: "Error while loading Role information.",
        Activate: "Activate",
        Deactivate: "Disable",
        ActivateRoleConfirmText: "Are you sure you want to activate the Role?",
        DeactivateRoleConfirmText: "Are you sure you want to disable the Role?",
        ActivateRoleSuccess: "Role activated!",
        DeactivateRoleSuccess: "Role deactivated!",
      },

      JOBPOSITIONS: {
        JobPositions: "Job Positions",

        INFOANDFORM: {
          Name: "Name",
          NamePlaceholder: "Ex: Operador de Logística",
          Goal: "Goal",
          GoalPlaceholder: "Ex: ",
          Description: "Description",
          DescriptionPlaceholder: "Ex: ",
          Qualification: "Qualification",
          QualificationPlaceholder: "Ex: ",
        },

        POPUP: {
          AddJobPosition: "Add Job Position",
          AddJobPositionSuccess: "Job Position added!",
          EditJobPosition: "Edit Job Position",
          EditJobPositionSuccess: "Job Position edited!",
          LoadingJobPositionError: "Error while loading Job Position information.",
          Activate: "Activate",
          Deactivate: "Disable",
          ActivateJobPositionConfirmText: "Are you sure you want to activate the Job Position?",
          DeactivateJobPositionConfirmText: "Are you sure you want to disable the Job Position?",
          ActivateJobPositionSuccess: "Job Position activated!",
          DeactivateJobPositionSuccess: "Job Position deactivated!",
        }
      },

      CRITERIAS: {
        Criterias: "Criterias",

        INFOANDFORM: {
          Name: "Name",
          NamePlaceholder: "Ex: Polivalência",
          Description: "Description",
          DescriptionPlaceholder: "Ex: ",
        },

        POPUP: {
          AddCriteria: "Add Criteria",
          AddCriteriaSuccess: "Criteria added!",
          EditCriteria: "Edit Criteria",
          EditCriteriaSuccess: "Criteria edited!",
          LoadingCriteriaError: "Error while loading Criteria information.",
          Delete: "Delete",
          DeleteCriteriaConfirmText: "Are you sure you want to delete the Criteria?",
          DeleteCriteriaSuccess: "Criteria deleted!",
        }
      }
    },

    MATRIX: {
      MatrixDetails: "Matrix Details",
      EvaluationMatrix: "Evaluation Matrix",
      EvaluationMatrices: "Evaluation Matrices",
      CreateMatrix: "Create Matrix",
      CreateNewMatrix: "Create New Matrix",
      NewMatrix: "New Matrix",
      EditMatrix: "Edit Matrix",
      CurrentMatrix: "Current Matrix",
      SelectRoles: "Select Roles",
      SelectSkills: "Select Skills",
      AddChecklistItem: "Add Item",

      INFOANDFORM: {
        BasicInfo: "Basic Info",
        Area: "Area",
        AreaPlaceholder: "Ex: Production Cutting & Needling",
        Manager: "Manager",
        ManagerPlaceholder: "Ex: john.doe@domain.com",
        CreationDate: "Creation Date",
        History: "History",
        EvaluationPeriods: "Evaluation Periods",
        Skill: "Skill",
        Level: "Level",
        Checklist: "Checklist",
        Description: "Description",
        DescriptionPlaceholder: "Type the description of the checklist item",
        Roles: "Roles",
        RolesPlaceholder: "Select roles",
        Skills: "Skills",
        SkillsPlaceholder: "Select skills",
        Name: "Name",
      },

      NOTIFICATIONS: {
        CreateMatrixSuccess: "Matrix created.",
        EditMatrixSuccess: "Matrix edited.",
      },

      POPUP: {
        CreateMatrix: "Create Matrix",
        DeleteChecklistItemConfirmText: "Are you sure you want to delete the checklist item?",
        SelectRoles: "Select Roles",
        SelectRolesSuccess: "Roles added to matrix.",
        SelectSkills: "Select Skills",
        AddChecklistItem: "Add Item",
        ManageSkills: "Manage Skills",
        DeactivateMatrixConfirmText: "Are you sure you want to deactivate this Evaluation Matrix?",
        ActivateMatrixConfirmText: "Are you sure you want to activate this Evaluation Matrix?",
        DeactivateMatrixSuccess: "Matrix deactivated",
        ActivateMatrixSuccess: "Matrix activated"
      },

      ERRORS: {
        InputFieldInvalid: "Invalid values were found on competence level fields",
        NoRolesSelected: "No roles selected.",
        NoCategoriesSelected: "No skills categories selected.",
        NoSkillsSelected: "No skills selected.",
      }
    },

    CAREER: {
      CareerLadder: "Career Ladder",
      CreateCareerLadder: "Create Career",
      EditCareerLadder: "Edit Career",


      INFOANDFORM: {
        Role: "Role",
        RoleName: "Role name",
        RolePlaceholder: "Ex: Operador de Logística",
        Roles: "Roles",
        RolesPlaceholder: "Select a role",
        RoleNamePlaceholder: "Type a role name",
        JobGrade: "Job Grade",
        JobGradePlaceholder: "Ex: 4",
        DeprecatedFunction: "Deprecated function",
        FilterByActiveRoles: "Filter only by active roles",

      },

      POPUP: {
        AddRole: "Add Role",
        AddRoleSuccess: "Role added.",
        RemoveRoleSuccess: "Role removed.",
        RemovePositionRecordConfirmText: "Are you sure you pretend to remove the role record?"
      },

      ERRORS: {
        NoRolesSelected: "No roles selected.",
      }
    },
  },


  EMPLOYEES: {
    Employees: "Employees",

    INFOANDFORM: {
      Name: "Name",
      Email: "Email",
      Manager: "Manager",
      Role: "Role",
      Evaluation: "Evaluation",
    },

    EVALUATIONS: {
      Evaluations: "Evaluations",
      EvaluationPeriods: "Evaluation Periods",

      INFOANDFORM: {
        Name: "Name",
        Evaluation: "Evaluation",
      }
    },

    CAREER: {
      CareerLadder: "Career Ladder",

      INFOANDFORM: {
        JobGrade: "Job Grade",
        StartDate: "Start Date",
        StartDatePlaceholder: "Select the start date",
        EndDate: "End Date",
        EndDatePlaceholder: "Select the end date",
        BusinessLines: "Business Lines",
        BusinessLinesPlaceholder: "Select the business line",
        Roles: "Roles",
        RolesPlaceholder: "Select a role"
      },

      POPUPS: {
        SelectBusinessLine: "Select Business Line",
        RemoveRoleSuccess: "Role removed.",
        AddRole: "Add Role",
        AddRoleSuccess: "Role added.",
      }
    },

    BONUS: {
      Bonus: "Bonus",

      INFOANDFORM: {
        EvaluationPeriod: "Evaluation Period",
        Role: "Role",
        Evaluation: "Evaluation",
        EvaluationPeriodData: "Evaluation Period Data",
        Name: "Name",
        StartDate: "Start Date",
        EndDate: "End Date",
        CompanyEvaluationFactors: "Company Evaluation Factors",
        EmployeeEvaluationFactors: "Employee Evaluation Factors",
        Factor: "Factor",
        Feedback: "Feedback",
      },

      PERFORMANCE: {
        Performance: "Performance",
        Exceptional: "Exceptional",
        VeryGood: "Very Good",
        Good: "Good",
        Reasonable: "Reasonable",
        ToImprove: "Needs to improve",
      },
    },

    CERTIFICATIONS: {
      Certifications: "Certifications",
      AddCertificate: "Add",

      INFOANDFORM: {
        Name: "Name",
        NamePlaceholder: "Ex: Basic Health Care",
        Date: "Date",
        StartDate: "Start Date",
        EndDate: "End Date",
        DatePlaceholder: "Select a date",
        State: "Estado",
        StatePlaceholder: "Select a state",
        Approval: "Approval",
        Approved: "Approved",
        ViewAttachment: "View Attachment",
        Attachment: "Attachment",
        DeleteCertification: "Delete Certification",
      },

      POPUPS: {
        AddCertification: "Add Certification",
        AddCertificationSuccess: "Certification added.",
        AddCertificationUploadError: "An error ocurred while uploading the attachment file.",
        EditCertification: "Edit Certification",
        EditCertificationSuccess: "Certification edited.",
        DeleteCertificationConfirmText: "Are you sure you want to delete the selected certification?",
        DeleteCertificationSuccess: "Certification deleted.",
        ViewAttachmentError: "An error occured while trying to view the selected attachment.",
        ImportXLSXSuccess: "Certifications imported."
      }
    },

    ITEMS: {
      FollowUpItems: "Follow Up Items",
      AddFollowUpItem: "Add Item",

      INFOANDFORM: {
        Name: "Name",
        NamePlaceholder: "Ex: Improve communication",
        Description: "Description",
        DescriptionPlaceholder: "Type a description",
        Status: "Status",
        StatusPlaceholder: "Select status",
        StartDate: "Start Date",
        StartDatePlaceholder: "Select a date",
        ConclusionDate: "Conclusion Date",
        ConclusionDatePlaceholder: "Select a date",
        DeleteFollowUpItem: "Delete item",
      },

      POPUPS: {
        AddFollowUpItem: "Add Follow Up Item",
        AddFollowUpItemSuccess: "Item added.",
        EditFollowUpItem: "Edit Follow Up Item",
        EditEditFollowUpItemSuccess: "Item edited",
        DeleteFollowUpItemConfirmText: "Are you sure you want to delete the selected item?",
        DeleteFollowUpItemSuccess: "Item deleted.",
        ImportXLSXSuccess: "Items imported.",
      }
    }
  },

  GLOBALADMIN: {
    GlobalAdmin: "Global Administration",
    LOCATIONS: {
      Locations: "Locations",
      CreateLocation: "Create Location",
      AccessGroups: "Access Groups",

      INFO: {
        Name: "Name",
        NumGroups: "No. Groups",
        Type: "Type",
      },

      POPUP: {
        Activate: "Activate",
        ActivateConfirmText: "Are you sure you want to activate the location?",
        ActivateLocationSuccess: "Location activated",
        ActivateLocationError: "Error activating location!",
        CreateLocationSuccess: "Location created!",
        EditLocation: "Edit Location",
        EditLocationError: "Error editing location!",
        EditLocationSuccess: "Location edited!",
        DeactivateLocationError: "Error deactivating location!",
        Deactivate: "Disable",
        DeactivateConfirmText: "Are you sure you want to disable the location?",
        DeactivateLocationSuccess: "Location deactivated!"
      },

      ACCESSGROUPS: {
        AccessGroups: "Access Groups",

        INFOANDFORM: {
          Type: "Type",
          ID: "ID",
          Name: "Name",
          EditedGroupSuccess: "Access groups edited!",
          SubmitError: "Please, verify errors in the form!",
        },

        TYPES: {
          LocalAdmin: "Local Administrator",
          RH: "Human Resources",
          TeamManager: "Team Manager",
          Collaborator: "Collaborator"
        }
      },

      BUSINESSLINES: {
        BusinessLines: "Business Lines",

        INFOANDFORM: {
          Name: "Name",
          NamePlaceholder: "Ex: Services",
        },

        POPUP: {
          AddBusinessLine: "Add Business Line",
          AddBusinessLineSuccess: "Business Line added!",
          EditBusinessLine: "Edit Business Line",
          EditBusinessLineSuccess: "Business Line edited!",
          LoadingBusinessLineError: "Error while loading business line information.",
          Activate: "Activate",
          Deactivate: "Disable",
          ActivateBusinessLineConfirmText: "Are you sure you want to activate the business line?",
          DeactivateBusinessLineConfirmText: "Are you sure you want to disable the business line?",
          ActivateBusinessLineSuccess: "Business Line activated!",
          DeactivateBusinessLineSuccess: "Business Line deactivated!",
        }
      },

      SKILLS: {
        Skills: "Skills",
        SkillsCategories: "Skills Categories",
        SkillsCategoryDetails: "Skills Category Details",

        INFOANDFORM: {
          Category: "Category",
          NumOfSkills: "No. Skills",
          CategoryName: "Category Name",
          CategoryNamePlaceholder: "Ex: Behavioral Skills",
          SkillName: "Name",
          SkillNamePlaceholder: "Ex: Creating and Innovating",
        },

        POPUP: {
          AddSkillsCategory: "Add Skills Category",
          AddSkillsCategorySuccess: "Skills Category added!",
          EditSkillsCategory: "Edit Skills Category",
          EditSkillsCategorySuccess: "Skills Category edited!",
          LoadingSkillsCategoryError: "Error while loading Skills Category information.",
          Activate: "Activate",
          Deactivate: "Disable",
          ActivateSkillsCategoryConfirmText: "Are you sure you want to activate the Skills Category?",
          DeactivateSkillsCategoryConfirmText: "Are you sure you want to disable the Skills Category?",
          ActivateSkillsCategorySuccess: "Skills Category activated!",
          DeactivateSkillsCategorySuccess: "Skills Category deactivated!",
          AddSkill: "Add Skill",
          AddSkillSuccess: "Skill added!",
          EditSkill: "Edit Skill",
          EditSkillSuccess: "Skill edited!",
          LoadingSkillError: "Error while loading Skill information.",
          ActivateSkillConfirmText: "Are you sure you want to activate the Skill?",
          DeactivateSkillConfirmText: "Are you sure you want to disable the Skill?",
          ActivateSkillSuccess: "Skill activated!",
          DeactivateSkillSuccess: "Skill deactivated!",
          ImportXLSXSuccess: "Skill Categories imported"
        }
      },
    },
  },
};

