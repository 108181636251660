import "./MyProgressionLadderPage.css"

import { useCallback, useEffect, useMemo, useState } from "react";

import { EmployeePositionRecordDTO } from "../../../employees/models/dtos/EmployeePositionRecordDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { MyProgressionLadder } from "./components/tree-node/MyProgressionLadder";
import { MyService } from "../../services/MyService";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { EmptyState } from "../../../../lib/components/emptystates/EmptyState";

const svc = new MyService();


export function MyProgressionLadderPage() {
    const openPopup = usePopup();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const getMyPositionRecordsCall = useServiceCallPro(svc.getMyPositionRecords);

    const [positionRecords, setPositionRecords] = useState<EmployeePositionRecordDTO[]>([])


    /****************************
    * DATA REQUESTS
    *****************************/

    const getMyPositionRecords = useCallback(() => {
        if (!locationIDAsString) return;

        getMyPositionRecordsCall.invoke(locationIDAsString)
            .then((data) => {
                if (!data) {
                    return;
                }
                setPositionRecords(data.employeePositionRecords);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [getMyPositionRecordsCall, locationIDAsString, openPopup]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        if (!locationIDAsString) return;

        getMyPositionRecords();

    }, [locationIDAsString]);


    /****************************
     * USER ACTIONS
     *****************************/

    /****************************
    * CSS & HTML
    *****************************/

    const renderEmployeeProgression = useMemo(() => {
        if (positionRecords.length > 0) {
            return <MyProgressionLadder employeePositionRecords={positionRecords} />
        }
        else{
            return(<EmptyState/>)
        }
    }, [positionRecords])


    return <PageLayout
        tabTitle={translate("EMPLOYEES.CAREER.CareerLadder")}
        pageTitle={translate("EMPLOYEES.CAREER.CareerLadder")}
        className="my-career-ladder"
        showBreadcrumb
    >
        {getMyPositionRecordsCall.isLoading && <FullScreenLoader />}
        {renderEmployeeProgression}
    </PageLayout>;
}