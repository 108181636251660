import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationPeriodCreateDTO } from "../models/dtos/EvaluationPeriodCreateDTO";
import { EvaluationPeriodDetailsDTO } from "../models/dtos/EvaluationPeriodDetailsDTO";
import { EvaluationPeriodMatricesResponseDTO } from "../models/dtos/EvaluationPeriodMatricesResponseDTO";
import { EvaluationPeriodStatusUpdateDTO } from "../models/dtos/EvaluationPeriodStatusUpdateDTO";
import { EvaluationPeriodSummaryDTO } from "../models/dtos/EvaluationPeriodSummaryDTO";
import { EvaluationPeriodUpdateDTO } from "../models/dtos/EvaluationPeriodUpdateDTO";
import { EvaluationPeriodsResponseDTO } from "../models/dtos/EvaluationPeriodsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class EvaluationPeriodsService {


    getEvaluationPeriods(locationId: string, businessLineId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EvaluationPeriodsResponseDTO | null> {
        return HttpClient.sessionRequest<EvaluationPeriodsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return ({
                    periods: response.data.periods,
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }


    createEvaluationPeriods(locationId: string, businessLineId: string, requestDto: EvaluationPeriodCreateDTO): Promise<void> {
        return HttpClient.sessionRequest<EvaluationPeriodDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationPeriodByID(locationId: string, businessLineId: string, evaluationPeriodID: string): Promise<EvaluationPeriodDetailsDTO | null> {
        const mapBackendDataToDTO = (data: any): EvaluationPeriodDetailsDTO => {
            return {
                id: data.evaluationPeriodId,
                startDate: data.periodStartDate,
                endDate: data.periodEndDate,
                name: data.name,
                state: data.state,
                createdBy: data.createdBy,
                createdAt: data.creationDate,
                modifiedBy: data.lastModificationBy,
                modifiedAt: data.lastModificationDate,
                startEvaluationsDate: data.evaluationsStartDate,
                finalEvaluationsDate: data.evaluationsEndDate,
                startCalibrationsDate: data.calibrationsStartDate,
                finalCalibrationsDate: data.calibrationsEndDate,
            };
        };

        return HttpClient.sessionRequest<EvaluationPeriodDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}`),
        })
            .then((response) => {
                if (!response.data) return null;
                return mapBackendDataToDTO(response.data)
            })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationPeriodMatrices(locationId: string, businessLineId: string, evaluationPeriodID: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EvaluationPeriodMatricesResponseDTO | null> {
        return HttpClient.sessionRequest<EvaluationPeriodMatricesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}/EvaluationPeriodMatrices`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return {
                    matrices: response.data.matrices,
                    totalItems: response.data.totalItems
                }
            })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationPeriodByIDSummary(locationId: string, businessLineId: string, evaluationPeriodID: string): Promise<EvaluationPeriodSummaryDTO | null> {
        return HttpClient.sessionRequest<EvaluationPeriodSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}/Summary`),
        })
            .then((res) => {

                if (!res.data) return null;

                return res.data;
            })
            .catch((error) => {
                throw error;
            });
    }


    editEvaluationPeriod(locationID: string, businessLineId: string, evaluationPeriodID: string, requestDto: EvaluationPeriodUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateEvaluationPeriodStatus(locationID: string, businessLineId: string, evaluationPeriodID: string, requestDto: EvaluationPeriodStatusUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}/Status`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



/*     getEvaluationPeriodCollaborators(locationId: string, businessLineId: string, evaluationPeriodID: string, query: QueryOptions): Promise<void> {

        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}/Collaborators`),
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response) return null;
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    } */


     importEvaluationPeriodCollaborators(locationId: string, businessLineId: string, evaluationPeriodID: string, file: File): Promise<void> {
        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}/Collaborators/FromXLSX`),
            data: formData
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }
 




}
