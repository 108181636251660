import "./EvaluationPeriodMatrixDetailsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodMatrixService } from "../services/EvaluationPeriodMatrixService";
import { EvaluationSummariesResponseDTO } from "../models/dtos/EvaluationSummariesResponseDTO";
import { EvaluationSummaryDTO2 } from "../../evaluations/models/EvaluationSummaryDTO";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { Loader } from "../../../lib/components/loader/Loader";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { PeriodMatrixEvaluationsSummary } from "../models/dtos/PeriodMatrixEvaluationsSummaryDTO";
import { Permission } from "../../../models/api/gate/Permission";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../lib/components/tag/Tag";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { EvaluationsResponseDTO } from "../models/dtos/EvaluationsResponseDTO";
import { EvaluationSummaryDTO } from "../models/dtos/EvaluationSummaryDTO";
import { EvaluationState, EvaluationStatusTranslationTerms } from "../../evaluations/models/EvaluationStatus";
import { EvaluationPeriodMatrixSummaryDTO } from "../models/dtos/EvaluationPeriodMatrixSummaryDTO";
import { getEvaluationPeriodStateCss } from "../../../common/helpers/GetEvaluationPeriodStateCss";
import { getEvaluationStateCss } from "../../../common/helpers/getEvaluationStateCss";

var svc = new EvaluationPeriodMatrixService();

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];


export function EvaluationPeriodMatrixDetailsPage() {


    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const navigate = useNavigate();
    const { id: businessLineID, periodId, matrixId } = useParams();


    const getEvaluationPeriodMatrixSummaryCall = useServiceCallPro(svc.getPeriodMatrixEvaluationsSummary);
    const getEvaluationPeriodMatrixEvaluationsCall = useServiceCallPro(svc.getPeriodMatrixEvaluations);


    const [evaluationsSummaries, setEvaluationSummaries] = useState<EvaluationsResponseDTO>();
    const [evaluationPeriodMatrixSummary, setEvaluationPeriodMatrixSummary] = useState<EvaluationPeriodMatrixSummaryDTO>();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    var searchFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });

    const [searchWord, setSearchWord] = useState<string>();
    const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);



    /****************************
    * DATA REQUESTS
    *****************************/

    const getPeriodMatrixEvaluationsSummary = useCallback(() => {

        if (!locationID || !businessLineID || !periodId || !matrixId) return;

        getEvaluationPeriodMatrixSummaryCall.invoke(`${locationID}`, "" + businessLineID, periodId, matrixId)
            .then((data) => {
                if (data)
                    setEvaluationPeriodMatrixSummary(data);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, businessLineID, periodId, matrixId, getEvaluationPeriodMatrixSummaryCall.invoke, openPopup]);



    const getPeriodMatrixEvaluations = useCallback(() => {

        if (!locationID || !businessLineID || !periodId || !matrixId) return;

        var queryOpts: QueryOptions = {
            searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getEvaluationPeriodMatrixEvaluationsCall.invoke(`${locationID}`, "" + businessLineID, periodId, matrixId, queryOpts)
            .then((data) => {
                if (data)
                    setEvaluationSummaries(data);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, businessLineID, periodId, matrixId, searchWord, currentpage, itemsPerPage, getEvaluationPeriodMatrixEvaluationsCall.invoke, openPopup]);



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getPeriodMatrixEvaluationsSummary();
    }, []);


    useEffect(() => {
        getPeriodMatrixEvaluations();
    }, [currentpage, itemsPerPage, searchWord]);


    useEffect(() => {
        setCurrentPage(0);
        setSearchWord(debouncedSearchTerm);
    }, [debouncedSearchTerm]);




    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);



    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EvaluationSummaryDTO>[] = useMemo(
        () => [
            {
                columnKey: `employeeName`,
                cellRenderProp: (v) =>
                    <div className="employee-info">
                        <div className="small-copy employee-name">
                            {v.employee.userDisplayName ?? v.employee.userEmail}
                        </div>
                        <div className="caption employee-id">{v.employee.userEmployeeID ?? "-"}</div> 
                    </div>,
                width: "30%",
                headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
            },

            {
                cellRenderProp: (v) => v.roleName ?? "-",
                headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Role"),
                width: "30%",
            },
            {
                cellRenderProp: (v) => <Tag text={"" + v.referenceRoleValue} backgroundColor={"status-light-grey"} />,
                headerRender: translate("BUSINESSLINES.ROLES.INFOANDFORM.RoleReferenceValue"),
                width: "20%",
            },
            {
                cellRenderProp: (v) => {
                    return <Tag text={"" + v.finalScore} backgroundColor={v.finalScore >= v.referenceRoleValue ? "status-green" : "status-yellow"} />
                },
                headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.TotalEvaluation"),
                width: "10%",
            },
            {
                cellRenderProp: (v) => <Tag
                text={v?.state ? translate(EvaluationStatusTranslationTerms[v.state]) : "-"}
                backgroundColor={getEvaluationStateCss(v?.state ?? -1)}/>,
                headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.State"),
                width: "20%",
            }
        ], [evaluationsSummaries?.evaluations]);




    return (
        <PagePermissions permissions={basePermissions} >
            <PageLayout
                tabTitle={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.ColaboratorsToEvaluate")}
                pageTitle={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.ColaboratorsToEvaluate")}
                className="period-matrix-details"
                showBreadcrumb
            >


                <CardContainer className="period-matrix-data">

                    <div className="card-header-container">
                        <div className="header">
                            <div className="subtitle">
                                {translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.AreaData")}
                            </div>
                            {/* {evaluationPeriodMatrixSummary && <Tag
                                text={evaluationPeriodMatrixSummary.totalEvaluations - evaluationPeriodMatrixSummary.totalEvaluationsCompleted  + " / " + evaluationPeriodMatrixSummary.totalEvaluations}
                                backgroundColor={evaluationPeriodMatrixSummary.totalEvaluations === evaluationPeriodMatrixSummary.totalEvaluationsCompleted ? "status-green" : "status-yellow"}
                            />} */}
                        </div>
                    </div>

                    {!getEvaluationPeriodMatrixSummaryCall.isLoading ? <>
                        <FormSection unsetFlexGrow>
                            <InfoBlock label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Area")}
                                value={evaluationPeriodMatrixSummary?.description ?? "-"} />
                            <InfoBlock label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager")}
                                value={evaluationPeriodMatrixSummary?.manager.userDisplayName ?? "-"} />

                        </FormSection>
                    </> : <Loader />}

                </CardContainer>

                <FormFieldTextInput
                    formControl={searchFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />

                <ResponsiveDataTable
                    columnDefinitions={columns}
                    items={evaluationsSummaries?.evaluations || []}
                    totalitems={evaluationsSummaries?.totalItems || 0}
                    paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
                    currentpage={currentpage}
                    isLoading={getEvaluationPeriodMatrixEvaluationsCall.isLoading}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                    onClickRow={(evaluations) => navigate(`evaluations/${evaluations.id}`)}
                ></ResponsiveDataTable>

            </PageLayout >
        </PagePermissions>
    );
}

