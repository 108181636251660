import "./EmployeeActionItemsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AddEmployeeActionItemPopup } from "./popups/AddEmployeeActionItemPopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { EditEmployeeActionItemPopup } from "./popups/EditEmployeeActionItemPopup";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EmployeeActionItemStateTranslationTerms } from "../../models/dtos/EmployeeActionItemState";
import { EmployeeActionItemSummaryDTO } from "../../models/dtos/EmployeeActionItemSummaryDTO";
import { EmployeeActionItemsService } from "../../services/EmployeeActionItemsService";
import { EmployeeStatusTranslationTerms } from "../../models/dtos/EmployeeFollowUpItemStatus";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveButton } from "../../../../lib/components/buttons/ResponsiveButton";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import axios from "axios";
import { getEvaluationFollowUpItemStatusCss } from "../../../evaluations/helpers/getEvaluationFollowUpItemStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new EmployeeActionItemsService();

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];

export function EmployeeActionItemsPage() {
    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const windowResize = useWindowResize();
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { employeeId } = useParams();

    const [items, setItems] = useState<EmployeeActionItemSummaryDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const getEmployeeActionItemsCall = useServiceCallPro(svc.getEmployeeActionItems);
    const deleteActionItemCall = useServiceCallPro(svc.deleteEmployeeActioItem);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployeeActionItems = useCallback((controller: AbortController | undefined) => {
        if (!employeeId) return;

        getEmployeeActionItemsCall.invoke(locationIDAsString, employeeId, { page: currentpage, pageLength: itemsPerPage }, controller?.signal)
            .then((data) => {
                if (data) {
                    setItems(data.items);
                    setTotalItems(data.total)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString,
        employeeId,
        getEmployeeActionItemsCall.invoke,
        currentpage,
        itemsPerPage,
        openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        if (!employeeId) return;

        const controller = new AbortController();
        getEmployeeActionItems(controller);

        return () => {
            controller.abort()
        };
    }, [employeeId, currentpage, itemsPerPage]);



    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsClicked = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);



    const handleAddItemButtonClicked = useCallback(() => {
        if (!employeeId) return;

        openPopup(<AddEmployeeActionItemPopup employeeId={employeeId} locationId={locationIDAsString} onCompletedOperations={() => getEmployeeActionItems(undefined)} />)

    }, [employeeId, openPopup, locationIDAsString, getEmployeeActionItems]);




    const handleEditItemClicked = useCallback((actionItemId: number) => {
        if (!employeeId || !actionItemId) return;
        openPopup(<EditEmployeeActionItemPopup employeeValmetId={employeeId} locationId={locationIDAsString} actionItemId={"" + actionItemId} onCompletedOperations={() => getEmployeeActionItems(undefined)} />)
    }, [employeeId, openPopup, locationIDAsString, getEmployeeActionItems]);



    const handleDeleteItem = useCallback((actionItemId: number) => {
        if (!employeeId || !actionItemId) return;

        deleteActionItemCall.invoke(locationIDAsString, employeeId, "" + actionItemId)
            .then(() => {
                getEmployeeActionItems(undefined);
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.ITEMS.POPUPS.DeleteFollowUpItemSuccess")}
                    />
                );
            })
            .catch((error) => {
                if (!error) return;
                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, deleteActionItemCall.invoke, getEmployeeActionItems, createNotification, openPopup]);


    const handleDeleteItemWarningPopup = useCallback((actionItemId: number) => {
        openPopup(
            <WarningPopup
                className="delete-item-popup"
                onDismissClicked={() => closePopup()}
                onConfirmClicked={() => handleDeleteItem(actionItemId)}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Delete")}
            >
                {translate("EMPLOYEES.ITEMS.POPUPS.DeleteFollowUpItemConfirmText")}
            </WarningPopup>
        )
    }, [openPopup, closePopup, handleDeleteItem]);



    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeActionItemSummaryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.itemName,
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.Name"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => v.itemDescription,
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.Description"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => (
                <Tag
                    text={translate(EmployeeActionItemStateTranslationTerms[v.state])}
                    backgroundColor={getEvaluationFollowUpItemStatusCss(v.state)}
                    isTiny={windowResize < 1024}
                />
            ),
            headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Status"),
            isStatusCell: true,
            width: "120px"
        },
        {
            cellRenderProp: (v) => DateTimeHelper.formatDate(v.startDate),
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDate"),
            width: "140px",
        },

        {
            cellRenderProp: (v) => DateTimeHelper.formatDate(v.endDate),
            headerRender: translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDate"),
            width: "140px",
        },
        {
            cellRenderProp: (v) =>
                <div className="employee-info">
                    <div className="small-copy employee-name">
                        {v.createdBy.userDisplayName ?? v.createdBy.userEmail}
                    </div>
                    <div className="caption employee-id">{v.createdBy.userEmployeeID ?? "-"}</div>
                </div>,
            width: "15%",
            headerRender: translate("COMMON.RegisteredBy"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => {
                return (
                    <Tooltip text={translate("EMPLOYEES.ITEMS.POPUPS.EditFollowUpItem")}>
                        <ScalableIconButton
                            icon={<EditIcon />}
                            onClick={() => { v.employeeActionItemId && handleEditItemClicked(v.employeeActionItemId) }}
                            size={24}
                        />
                    </Tooltip>
                );
            },
            width: "24px",
            isMobileHeaderIcon: true,
        },
        {
            cellRenderProp: (v) => {
                return (
                    <Tooltip text={translate("EMPLOYEES.ITEMS.INFOANDFORM.DeleteFollowUpItem")}>
                        <ScalableIconButton
                            icon={<DeleteIcon />}
                            onClick={() => { v.employeeActionItemId && handleDeleteItemWarningPopup(v.employeeActionItemId) }}
                            size={24}
                        />
                    </Tooltip>
                );
            },
            width: "24px",
            isMobileHeaderIcon: true,
        }
    ], [handleDeleteItemWarningPopup, handleEditItemClicked, windowResize]);


    const renderActionButtons = useMemo(() => {
        if (isLoadingPermissions || !hasPermission || !employeeId)
            return null;

        return <ResponsiveButton
            text={translate("EMPLOYEES.ITEMS.AddFollowUpItem")}
            type="secondary"
            icon={<AddIcon />}
            onClick={handleAddItemButtonClicked}
        />;
    }, [isLoadingPermissions, hasPermission, employeeId, handleAddItemButtonClicked]);


    return <PageLayout
        tabTitle={translate("EMPLOYEES.ITEMS.FollowUpItems")}
        pageTitle={translate("EMPLOYEES.ITEMS.FollowUpItems")}
        className="items-page"
        showBreadcrumb
        actions={renderActionButtons}
    >
        {deleteActionItemCall.isLoading && <FullScreenLoader />}
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={items || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getEmployeeActionItemsCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsClicked}
        />
    </PageLayout>;
}