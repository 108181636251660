import "./BonusEmployeeEvaluationCard.css";

import { useMemo } from "react";

import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { TitledCardContainer } from "../../../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { finalBonusScoreFromPercentage } from "../../../../../common/helpers/finalBonusScoreFromPercentage";
import { EmployeeBonusEvaluationSummaryDTO } from "../../../models/dtos/EmployeeBonusEvaluationSummaryDTO";
import { EmployeeBonusResponseDTO } from "../../../models/dtos/EmployeeBonusReponseDTO";

interface IBonusEmployeeEvaluationCardProps {
    isLoading: boolean;
    items: EmployeeBonusResponseDTO | undefined;
}



export function BonusEmployeeEvaluationCard(props: IBonusEmployeeEvaluationCardProps) {

    /****************************
    * DATA REQUESTS
    *****************************/

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    /****************************
     * CSS & HTML
     *****************************/
    const columns: ColumnDefinition<EmployeeBonusEvaluationSummaryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.periodName,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Period"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => item.score ? finalBonusScoreFromPercentage(item.score * 100) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation"),
            width: "15%",
            isMobilePrimaryCell: true,
        },
    ], []);


    return <TitledCardContainer className="bonus-evaluation-card" title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Bonus")}>
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={props.items?.records || []}
            totalitems={props.items?.totalItems || 0}
            isLoading={props.isLoading}

        />
    </TitledCardContainer>;
}