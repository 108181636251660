import "./EvaluationPeriodDetailsPage.css";

import { EvaluationPeriodState, EvaluationPeriodStateTranslationTerms } from "../../../models/enums/EvaluationPeriodState";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../lib/components/buttons/Button";
import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { ReactComponent as CheckIcon } from "../../../lib/assets/icons/check-btn.svg";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../lib/helpers/datetime/DateTime";
import { EditEvaluationPeriodPopup } from "./popups/EditEvaluationPeriodPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodDetailsDTO } from "../models/dtos/EvaluationPeriodDetailsDTO";
import { EvaluationPeriodMatricesResponseDTO } from "../models/dtos/EvaluationPeriodMatricesResponseDTO";
import { EvaluationPeriodMatrixSummaryDTO } from "../models/dtos/EvaluationPeriodMatrixSummaryDTO";
import { EvaluationPeriodsService } from "../services/EvaluationPeriodsService";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import.svg";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { Loader } from "../../../lib/components/loader/Loader";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ReactComponent as ReOpenIcon } from "../../../lib/assets/icons/reopen.svg";
import { ReactComponent as RejectIcon } from "../../../lib/assets/icons/reject.svg";
import { ResponsiveButton } from "../../../lib/components/buttons/ResponsiveButton";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../lib/components/tag/Tag";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import axios from "axios";
import { getEvaluationMatrixStateCss } from "../../../common/helpers/getEvaluationMatrixStateCss";
import { getEvaluationPeriodStateCss } from "../../../common/helpers/GetEvaluationPeriodStateCss";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";

var svc = new EvaluationPeriodsService();

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EvaluationPeriodDetailsPage() {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID]);
    const { id: businessLineID, periodId: evaluationPeriodID } = useParams();
    const createNotification = useCreateNotification();
    const updateRouteDate = useUpdatePageData();
    const navigate = useNavigate();

    const getPeriodSummaryInfoCall = useServiceCallPro(svc.getEvaluationPeriodByID);
    const getEvaluationPeriodMatricesCall = useServiceCallPro(svc.getEvaluationPeriodMatrices);
    const updateEvaluationPeriodStateCall = useServiceCallPro(svc.updateEvaluationPeriodStatus);
    const postEvaluationPeriodCollaboratorsCall = useServiceCallPro(svc.importEvaluationPeriodCollaborators);


    const { isLoadingPermissions: loadingTopPermissions, hasPermission: hasTopPermissions } = useHasPermissions(topPermissions);


    const [periodSummaryInfo, setPeriodSummaryInfo] = useState<EvaluationPeriodDetailsDTO>();
    const [evaluationPeriodMatrices, setEvaluationPeriodMatrices] = useState<EvaluationPeriodMatricesResponseDTO>();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    var searchFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });

    const [searchWord, setSearchWord] = useState<string>();
    const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);


    /****************************
   * DATA REQUESTS
   *****************************/

    const getPeriodSummaryInfo = useCallback(() => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID) return;

        getPeriodSummaryInfoCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID)
            .then((data) => {
                if (data) {
                    setPeriodSummaryInfo(data);
                    updateRouteDate(["##EVALUATIONPERIOD##", data.name]);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, evaluationPeriodID, getPeriodSummaryInfoCall.invoke, openPopup, setPeriodSummaryInfo]);


    const getEvaluationPeriodMatrices = useCallback((controller: AbortController | undefined) => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID) return;

        var query: QueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchFormControl.value
        };

        getEvaluationPeriodMatricesCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, query, controller?.signal)
            .then((data) => {
                if (data) {
                    setEvaluationPeriodMatrices(data);
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, evaluationPeriodID, currentpage, itemsPerPage, searchFormControl.value, getEvaluationPeriodMatricesCall.invoke, businessLineID, openPopup]);


    const updateEvaluationPeriodState = useCallback((newState: EvaluationPeriodState, notificationSuccessMessage: string) => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID) return;

        updateEvaluationPeriodStateCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, { state: newState })
            .then((_) => {
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(notificationSuccessMessage)}
                    />
                );
                getPeriodSummaryInfo();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, evaluationPeriodID, updateEvaluationPeriodStateCall.invoke, businessLineID, createNotification, getPeriodSummaryInfo, openPopup]);



    const handleCollaboratorsFileImport = useCallback((file: File) => {
        if (!locationIDAsString || !businessLineID || !evaluationPeriodID) return;

        postEvaluationPeriodCollaboratorsCall.invoke(locationIDAsString, businessLineID, evaluationPeriodID, file)
            .then((_) => {

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("BUSINESSLINES.EVALUATIONPERIODS.COLLABORATORS.POPUPS.ImportSuccess")}
                    />
                );
                getEvaluationPeriodMatrices(undefined);
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [getEvaluationPeriodMatrices, locationIDAsString, businessLineID, evaluationPeriodID, postEvaluationPeriodCollaboratorsCall.invoke, createNotification, openPopup]);




    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getPeriodSummaryInfo();
    }, []);


    useEffect(() => {
        const controller = new AbortController();
        getEvaluationPeriodMatrices(controller);
        return () => { controller.abort() };
    }, [currentpage, itemsPerPage, searchWord]);


    useEffect(() => {
        setCurrentPage(0);
        setSearchWord(debouncedSearchTerm);
    }, [debouncedSearchTerm]);


    const isPeriodClosed = useMemo(() => {
        return periodSummaryInfo?.state === EvaluationPeriodState.Completed || periodSummaryInfo?.state === EvaluationPeriodState.Canceled;
    }, [periodSummaryInfo]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleCancelPeriodButtonClicked = useCallback(() => {
        openPopup(
            <WarningPopup
                className="cancel-evaluation-period-popup"
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={() => { updateEvaluationPeriodState(EvaluationPeriodState.Canceled, "BUSINESSLINES.EVALUATIONPERIODS.MESSAGES.PeriodCanceledSuccess"); }}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("BUSINESSLINES.EVALUATIONPERIODS.POPUPS.Cancel")}
            >
                {translate("BUSINESSLINES.EVALUATIONPERIODS.POPUPS.CancelEvaluationPeriodConfirmText")}
            </WarningPopup>
        );
    }, [closePopup, openPopup, updateEvaluationPeriodState]);


    const handleActivatePeriodButtonClicked = useCallback(() => {
        openPopup(
            <WarningPopup className="activate-evaluation-period-popup"
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={() => { updateEvaluationPeriodState(EvaluationPeriodState.InProgress, "BUSINESSLINES.EVALUATIONPERIODS.MESSAGES.PeriodAtivatedSuccess"); }}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Activate")}
            >
                {translate("BUSINESSLINES.EVALUATIONPERIODS.POPUPS.ActivateEvaluationPeriodConfirmText")}
            </WarningPopup>
        );
    }, [closePopup, openPopup, updateEvaluationPeriodState]);


    const handleClosePeriodButtonClicked = useCallback(() => {
        openPopup(
            <WarningPopup
                className="close-evaluation-period-popup"
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={() => { updateEvaluationPeriodState(EvaluationPeriodState.Completed, "BUSINESSLINES.EVALUATIONPERIODS.MESSAGES.PeriodCompletedSuccess"); }}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Close")}
            >
                {translate("BUSINESSLINES.EVALUATIONPERIODS.POPUPS.CloseEvaluationPeriodConfirmText")}
            </WarningPopup>
        );
    }, [closePopup, openPopup, updateEvaluationPeriodState]);


    const handleReopenPeriodButtonClicked = useCallback(() => {
        openPopup(
            <WarningPopup
                className="reopen-evaluation-period-popup"
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={() => { updateEvaluationPeriodState(EvaluationPeriodState.InProgress, "BUSINESSLINES.EVALUATIONPERIODS.MESSAGES.PeriodReopenSuccess"); }}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Reopen")}
            >
                {translate("BUSINESSLINES.EVALUATIONPERIODS.POPUPS.ReopenEvaluationPeriodConfirmText")}
            </WarningPopup>
        );
    }, [closePopup, openPopup, updateEvaluationPeriodState]);


    const handleEditPeriodButtonClicked = useCallback(() => {
        openPopup(
            <EditEvaluationPeriodPopup
                locationID={locationIDAsString}
                businessLineId={`${businessLineID}`}
                periodID={`${evaluationPeriodID}`}
                onCompletedOperations={getPeriodSummaryInfo}
            />
        );
    }, [businessLineID, evaluationPeriodID, getPeriodSummaryInfo, locationIDAsString, openPopup]);



    const handleClickImportCollaboratorsBtn = useCallback(() => {
        openPopup(
            <ImportPopup
                warningMessage={translate("BUSINESSLINES.EVALUATIONPERIODS.COLLABORATORS.POPUPS.ImportCollaboratorsConfirmText")}
                onClickDownloadTemplateBtn={() => window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Skills_Import_Collaborators_Template_V2.xlsx", "_blank")}
                onClickImportBtn={handleCollaboratorsFileImport} />
        );
    }, [handleCollaboratorsFileImport, openPopup]);

    /****************************
     * CSS & HTML
     *****************************/

    const renderCardContainerSubtitleHeader = useMemo(() => {
        const createdBy = periodSummaryInfo?.createdBy.userDisplayName;
        const createdAt = periodSummaryInfo?.createdAt;

        if (!createdBy || !createdAt) return null;

        return (
            <div className="sub-header-period-data">
                <div className="small-copy created-date">
                    {`${translate("COMMON.CreatedBy")}: ${createdBy} ${translate("COMMON.PrepositionForDateTime")} ${DateTimeHelper.formatDateTimeLocale(createdAt)}`}
                </div>
            </div>
        );
    }, [periodSummaryInfo?.createdBy.userDisplayName, periodSummaryInfo?.createdAt]);


    const renderPageActionsBtns = useMemo(() => {
        if (!periodSummaryInfo?.state) return null;
        if (loadingTopPermissions || !hasTopPermissions) return null;

        let actionButtons;

        switch (periodSummaryInfo.state) {
            case EvaluationPeriodState.Registered:
                actionButtons = (
                    <>
                        <ResponsiveButton
                            text={translate("BUSINESSLINES.EVALUATIONPERIODS.COLLABORATORS.ImportCollaborators")}
                            type="secondary"
                            icon={<ImportIcon />}
                            onClick={handleClickImportCollaboratorsBtn}
                        />
                        <ResponsiveButton
                            text={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.ImportAbsences")}
                            type="secondary"
                            icon={<ImportIcon />}
                            onClick={() => navigate("absences")}
                        />
                        <ResponsiveButton icon={<RejectIcon />} text={translate("COMMON.Cancel")} className="button-red" type="primary"
                            onClick={handleCancelPeriodButtonClicked} />
                        <ResponsiveButton icon={<CheckIcon />} text={translate("COMMON.Activate")} type="primary" className="button-blue"
                            onClick={handleActivatePeriodButtonClicked} />
                    </>
                );
                break;
            case EvaluationPeriodState.InProgress:
                actionButtons = (
                    <>
                        <ResponsiveButton
                            text={translate("BUSINESSLINES.EVALUATIONPERIODS.COLLABORATORS.ImportCollaborators")}
                            type="secondary"
                            icon={<ImportIcon />}
                            onClick={handleClickImportCollaboratorsBtn}
                        />
                        <ResponsiveButton
                            text={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.ImportAbsences")}
                            type="secondary"
                            icon={<ImportIcon />}
                            onClick={() => navigate("absences")}
                        />
                        <ResponsiveButton icon={<RejectIcon />} text={translate("COMMON.Cancel")} className="button-red" type="primary"
                            onClick={handleCancelPeriodButtonClicked} />
                        <ResponsiveButton icon={<CheckIcon />} text={translate("COMMON.Close")} type="primary"
                            onClick={handleClosePeriodButtonClicked} />
                    </>
                );
                break;
            case EvaluationPeriodState.Completed:
                actionButtons = (
                    <ResponsiveButton icon={<ReOpenIcon />} text={translate("COMMON.Reopen")} type="primary"
                        onClick={handleReopenPeriodButtonClicked} />
                );
                break;
            case EvaluationPeriodState.Canceled:
                actionButtons = (
                    <ResponsiveButton icon={<ReOpenIcon />} text={translate("COMMON.Reopen")} type="primary"
                        onClick={handleReopenPeriodButtonClicked} />
                );
                break;
            default:
                actionButtons = null;
        }

        return (
            <>
                {actionButtons}</>
        );
    }, [periodSummaryInfo?.state, loadingTopPermissions, hasTopPermissions, handleCancelPeriodButtonClicked, handleActivatePeriodButtonClicked, handleClosePeriodButtonClicked, handleReopenPeriodButtonClicked, handleClickImportCollaboratorsBtn, navigate]);


    const renderEditPeriodDetailsButton = useMemo(() => {
        if (!periodSummaryInfo || isPeriodClosed || loadingTopPermissions || !hasTopPermissions)
            return null;

        return (
            <div className="period-data-action-buttons">
                <Button
                    text={translate("COMMON.Edit")}
                    type="secondary"
                    isDisabled={isPeriodClosed}
                    onClick={handleEditPeriodButtonClicked}
                />
            </div>
        );
    }, [periodSummaryInfo, isPeriodClosed, loadingTopPermissions, hasTopPermissions, handleEditPeriodButtonClicked]);


    const columns: ColumnDefinition<EvaluationPeriodMatrixSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.description,
                headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Area"),
                isMobilePrimaryCell: true,
                width: "40%",
            },
            {
                cellRenderProp: (v) => v.manager.userDisplayName ?? "-",
                headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager"),
                width: "40%",
            },
        ], []);


    return (
        <PagePermissions permissions={basePermissions} >
            <PageLayout
                tabTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.EvaluationPeriodDetails")}
                showBreadcrumb
                pageTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.EvaluationPeriodDetails")}
                className="period-details"
                actions={renderPageActionsBtns}
            >
                {updateEvaluationPeriodStateCall.isLoading || postEvaluationPeriodCollaboratorsCall.isLoading ? <FullScreenLoader /> : null}

                <CardContainer className="period-data">
                    <div className="card-header-container">
                        <div className="header">
                            <div className="subtitle">
                                {translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EvaluationPeriodData")}
                            </div>
                            {periodSummaryInfo?.state && <Tag
                                text={periodSummaryInfo?.state ? translate(EvaluationPeriodStateTranslationTerms[periodSummaryInfo?.state]) : "-"}
                                backgroundColor={getEvaluationPeriodStateCss(periodSummaryInfo?.state ?? -1)}
                            />}
                        </div>
                        {renderCardContainerSubtitleHeader}
                    </div>

                    {!getPeriodSummaryInfoCall.isLoading ? <>
                        <FormSection unsetFlexGrow>
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Name")}
                                value={periodSummaryInfo?.name ? periodSummaryInfo?.name : "-"} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartDate")}
                                value={DateTimeHelper.formatDateTimeLocale(periodSummaryInfo?.startDate)} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndDate")}
                                value={DateTimeHelper.formatDateTimeLocale(periodSummaryInfo?.endDate)} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartEvaluations")}
                                value={DateTimeHelper.formatDateTimeLocale(periodSummaryInfo?.startEvaluationsDate)} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndEvaluations")}
                                value={DateTimeHelper.formatDateTimeLocale(periodSummaryInfo?.finalEvaluationsDate)} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartCalibrations")}
                                value={DateTimeHelper.formatDateTimeLocale(periodSummaryInfo?.startCalibrationsDate)} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndCalibrations")}
                                value={DateTimeHelper.formatDateTimeLocale(periodSummaryInfo?.finalCalibrationsDate)} />
                        </FormSection>

                        {renderEditPeriodDetailsButton}
                    </> : <Loader />}

                </CardContainer>

                <div className="period-matrices">
                    <FormFieldTextInput
                        formControl={searchFormControl}
                        icon={<SearchIcon />}
                        placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                        className="search-box"
                    />
                    <ResponsiveDataTable
                        columnDefinitions={columns}
                        items={evaluationPeriodMatrices?.matrices || []}
                        totalitems={evaluationPeriodMatrices?.totalItems || 0}
                        paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
                        currentpage={currentpage}
                        isLoading={getEvaluationPeriodMatricesCall.isLoading}
                        onPageAndItemsChanged={handleOnPageAndItemsChanged}
                        onClickRow={(period) => navigate(`matrices/${period.matrixId}`)}
                    />
                </div>
            </PageLayout >
        </PagePermissions>
    );
}

